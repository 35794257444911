import React, { Component } from 'react';
import { ISetupAssurancesProps, ISetupAssurancesState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import ImagePicker from '@kurtosys/ksys-app-components/dist/components/base/ImagePicker';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import IconInput from '../App/styledComponents/IconInput';
import Wrapper from './styledComponents/Wrapper';
import Heading from './styledComponents/Heading';
import InfoMessage from '../App/styledComponents/InfoMessage';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import Label from './styledComponents/Label';
@inject('appStore', 'setupAssurancesStore', 'messageStore')
@observer
export class SetupAssurances extends Component<ISetupAssurancesProps, ISetupAssurancesState> {
	static configurationKey: 'setupAssurances' = 'setupAssurances';
	static styleKey: 'setupAssurances' = 'setupAssurances';
	constructor(props: ISetupAssurancesProps) {
		super(props);
	}
	render() {
		const { className, setupAssurancesStore, messageStore, appStore } = this.props;

		if (!setupAssurancesStore || !messageStore) {
			return null;
		}

		const {
			heading,
			imagePickerProps,
			messagePlaceholderText,
			messageLabelText,
			updateMessage,
			message,
			messageValid,
			messageRemainingCharactersText,
			messageRemainingCharactersCount,
			instructionText,
			buttonText,
			handleUpdateAssurances,
			isValid,
		} = setupAssurancesStore;

		return (
			<Wrapper className={className}>
				{heading && <Heading data-qa-ref="setupAssurancesHeading">{heading}</Heading>}
				{instructionText && <InfoMessage>{instructionText}</InfoMessage>}
				<form
					noValidate={appStore && appStore.disableBrowserValidation}
					spellCheck={true}
					autoSave="false"
					onSubmit={(e) => {
						e.preventDefault();
						return false;
					}}
				>
					{messageLabelText && (
						<Label htmlFor="assuranceMessageInput" value={messageLabelText} />
					)}
					<IconInput
						id="assuranceMessageInput"
						name="assuranceMessage"
						aria-label={!messageLabelText && messagePlaceholderText}
						placeholder={messagePlaceholderText}
						autoFocus
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							updateMessage(event.target.value)
						}
						value={message}
						iconProps={{ asset: 'base.iconInput.message' }}
						hasError={!messageValid}
						data-qa-ref="assuranceMessageInput"
						required
						aria-required="true"
						spellCheck={true}
					/>
					<InfoMessage>
						<Translate placeholders={{ count: messageRemainingCharactersCount }}>
							{messageRemainingCharactersText}
						</Translate>
					</InfoMessage>
					<ImagePicker {...imagePickerProps} />
					<ErrorMessage data-qa-ref="loginErrorMessage">
						{messageStore.errorText}
					</ErrorMessage>
					<ButtonRaised
						disabled={!isValid}
						onClick={handleUpdateAssurances}
						data-qa-ref="updateAssurancesButton"
					>
						{buttonText}
					</ButtonRaised>
				</form>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(SetupAssurances, 'setupAssurances');
