import React from 'react';
import ReactDOM from 'react-dom';
import { appsManager, utils } from '@kurtosys/ksys-app-template';
import { Provider } from 'mobx-react';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { Manifest } from './configuration/Manifest';
import { getStoreContextForElement, getStoreKey } from './utils/store';
import { EVENTS } from './utils/events';
import { getCurrentScript } from './utils/scripts';
import { initializeGlobalEvents } from './utils/globalEvents';
import { initializeGlobalDisclaimers } from './utils/globalDisclaimers';
import { RE_INITIALIZE_ON_EMBED_ADD } from './constants';

const manifest = new Manifest();
const URL = window.location.href;
const hasManager =
	!manifest.bypassAppManager &&
	document.querySelectorAll('[data-ksys-app-template-id="ksys-app-manager"]').length > 0;
if (hasManager) {
	addManagerEventListeners();
}

export function getApplicationCode() {
	if (manifest.isDevelopment) {
		return manifest.testGuid;
	}
	const currentScript = getCurrentScript(manifest);
	if (currentScript) {
		const { src } = currentScript as any;
		const regex = /\/apps\/(.*)\/main-ksys-(.*)\.js/;
		const match = regex.exec(src);
		if (match && match.length > 1) {
			const [, applicationCode] = match;
			return applicationCode;
		}
	}
	return;
}

const readyForRender = (element: HTMLElement) => {
	return !(
		element.dataset &&
		element.dataset.dynamicLoad === 'true' &&
		element.dataset.embedAdded !== 'true'
	);
};

function renderAppToTargets(elements?: HTMLElement[], reInitializeStore: boolean = false) {
	const applicationCode = getApplicationCode();
	if (!elements) {
		elements = appsManager.utils.getElementsForManifest(manifest).filter(readyForRender);
	}
	if (hasManager) {
		elements = elements.filter((element: HTMLElement) => {
			return element && element.dataset && element.dataset.managerInitialized === 'true';
		});
	}

	const elementsForApplication = elements.filter((element) => {
		const dataset = utils.element.getOrchestratedDataset(element);
		const { applicationCode: elementApplicationCode } = dataset;
		return (
			!applicationCode ||
			!elementApplicationCode ||
			applicationCode === elementApplicationCode
		);
	});

	if (elementsForApplication && elementsForApplication.length > 0) {
		for (const element of elementsForApplication) {
			// Get the first child of the embed to use as the loading icon
			const embedLoadingElement = element.firstElementChild;
			if (!element.dataset || element.dataset.initialized !== 'true') {
				const storeKey = getStoreKey(element, URL, manifest);
				const storeContext = getStoreContextForElement(
					element,
					URL,
					manifest,
					storeKey,
					reInitializeStore,
				);
				const providerProps = (storeContext && storeContext.providerProps) || {};
				ReactDOM.render(
					<Provider {...providerProps}>
						<App embedLoadingElement={embedLoadingElement} />
					</Provider>,
					element,
				);
				element.dataset.initialized = 'true';
				(element as any)['__ksysStoreKey__'] = storeKey;
			}
		}
	}
}

export function addEventListeners() {
	removeEventListeners();
	document.addEventListener(EVENTS.KSYS_APP_EMBED_ADDED, embedAddedHandler);
	document.addEventListener(EVENTS.KSYS_APP_PREVIEW_UPDATED, previewUpdatedHandler);
}

export function removeEventListeners() {
	document.removeEventListener(EVENTS.KSYS_APP_EMBED_ADDED, embedAddedHandler);
	document.addEventListener(EVENTS.KSYS_APP_PREVIEW_UPDATED, previewUpdatedHandler);
}

export function addManagerEventListeners() {
	document.addEventListener(EVENTS.KSYS_APP_MANAGER_INITIALIZED, managerInitializedHandler);
}

export function removeManagerEventListeners() {
	document.removeEventListener(EVENTS.KSYS_APP_MANAGER_INITIALIZED, managerInitializedHandler);
}

export function handleAppRender(
	event: Event,
	forceRender: boolean = false,
	reInitializeStore: boolean = false,
) {
	const { target } = event;
	if (target) {
		const dataset = utils.element.getOrchestratedDataset(target as HTMLElement);
		if (dataset) {
			const { ksysAppTemplateId, initialized } = dataset;
			if (
				(initialized !== 'true' || forceRender) &&
				ksysAppTemplateId === manifest.ksysAppTemplateId
			) {
				console.log(`New App render fired`, target);
				renderAppToTargets([target as any], reInitializeStore);
			}
		}
	}
}

function managerInitializedHandler(event: Event) {
	handleAppRender(event);
}

function embedAddedHandler(event: Event) {
	const { target } = event;
	if (target) {
		const { dataset } = target as HTMLElement;
		dataset.embedAdded = 'true';
		handleAppRender(event, undefined, RE_INITIALIZE_ON_EMBED_ADD);
	}
}

function previewUpdatedHandler(event: Event) {
	const target = event.target as HTMLElement;
	if (target) {
		const storeContext = getStoreContextForElement(target, URL, manifest);
		if (storeContext && storeContext.appStore && storeContext.appStore.loadPreviewContext) {
			storeContext.appStore.loadPreviewContext();
		}
	}
}

renderAppToTargets();
initializeGlobalEvents();
initializeGlobalDisclaimers();
addEventListeners();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
