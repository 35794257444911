import { computed, action, observable } from 'mobx';
import { utils } from '@kurtosys/ksys-app-template';
import { StoreBase } from '../../../common/StoreBase';
import { ISetPasswordConfiguration, SetPasswordMode, ISetPasswordMode, ISetPasswordModes, ISetPasswordOptions } from '../models';
import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { IUpdatePasswordRequest } from '@kurtosys/ksys-api-client/dist/models/requests/auth/IUpdatePasswordRequest';
import { TValidateCodeRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TValidateCodeRequestBody';
import { TValidateCodeResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TValidateCodeResponseBody';
import { config } from '../../App/models/config';
import { IQueryOptions } from '../../../models/commonTypes';

const DEFAULT_EXPIRATION_TEXT: IQueryOptions = {
	queryOptionsType: 'none',
	options: {
		none: {
			value: `{sub:clientName} requires you to change your password every {sub:maxPasswordAge} days.`,
			subQueries: [
				{
					queryId: 'clientName',
					queryOptionsType: 'response',
					options: {
						response: {
							key: 'clientName',
						},
					},
				},
				{
					queryId: 'maxPasswordAge',
					queryOptionsType: 'response',
					options: {
						response: {
							key: 'maxPasswordAge',
						},
					},
				},
			],
		},
	},
};

const DEFAULT_URL_PATTERN: IQueryOptions = {
	queryOptionsType: 'none',
	value: '/:param1',
};

const DEFAULT_URL_PARAM1: IQueryOptions = {
	queryOptionsType: 'url',
	urlPattern: DEFAULT_URL_PATTERN,
	code: 'param',
	key: 'param1',
};

const DEFAULT_MODE_PASSWORD: ISetPasswordMode = {
	query: DEFAULT_URL_PARAM1,
	value: {
		queryOptionsType: 'none',
		value: 'set-password',
	},
	options: {
		heading: 'Set Your Password',
	},
};

const DEFAULT_MODE_EXPIRATION: ISetPasswordMode = {
	query: DEFAULT_URL_PARAM1,
	value: {
		queryOptionsType: 'none',
		value: 'password-expired',
	},
	options: {
		redirectOnSuccess: true,
		heading: 'Password Expired',
		text: 'Please reset your password to continue using the application',
	},
};

const DEFAULT_MODE_REGISTRATION: ISetPasswordMode = {
	query: DEFAULT_URL_PARAM1,
	value: {
		queryOptionsType: 'none',
		value: 'register',
	},
};

export class SetPasswordStore extends StoreBase {
	static componentKey: 'setPassword' = 'setPassword';
	@observable.ref codeValidation: TValidateCodeResponseBody | undefined = undefined;
	@observable password: string = '';
	@observable confirmPassword: string = '';
	@observable isValid: boolean = false;
	@observable registrationConfirmationToken: string = '';

	@computed
	get configuration(): ISetPasswordConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SetPasswordStore.componentKey);
		}
	}

	@computed
	get translationStore() {
		const { translationStore } = this.storeContext;
		return translationStore;
	}

	@computed
	get modeKey(): SetPasswordMode {
		let mode: SetPasswordMode = SetPasswordMode.password;
		const modes = this.modes;
		const { password, expiration, registration } = modes;
		if (password && this.evaluatePasswordMode(password)) {
			mode = SetPasswordMode.password;
		}
		else if (expiration && this.evaluatePasswordMode(expiration)) {
			mode = SetPasswordMode.expiration;
		}
		else if (registration && this.evaluatePasswordMode(registration)) {
			mode = SetPasswordMode.registration;
		}
		return mode;
	}
	@computed
	get mode(): ISetPasswordMode | undefined {
		const modeKey = this.modeKey;
		switch (modeKey) {
			case SetPasswordMode.password:
				return this.modes.password;
			case SetPasswordMode.expiration:
				return this.modes.expiration;
			case SetPasswordMode.registration:
				return this.modes.registration;
			default:
				break;
		}
		return;
	}

	@computed
	get modes(): ISetPasswordModes {
		const response: ISetPasswordModes = {
			password: DEFAULT_MODE_PASSWORD,
			expiration: DEFAULT_MODE_EXPIRATION,
			registration: DEFAULT_MODE_REGISTRATION,
		};
		if (this.configuration) {
			const { modes = {} } = this.configuration;
			Object.assign(response, modes);
		}
		return response;
	}

	@computed
	get options(): ISetPasswordOptions {
		const mode = this.mode;
		const response: ISetPasswordOptions = utils.object.deepMergeObjects(
			(this.defaultOptions || {}),
			(mode && mode.options) || {},
		);
		if (this.modeKey === SetPasswordMode.expiration) {
			if (typeof response.redirectOnSuccess !== 'boolean') {
				response.redirectOnSuccess = true;
			}
		}
		return response;
	}

	evaluatePasswordMode = (setPasswordMode: ISetPasswordMode) => {
		const { queryStore } = this.storeContext;
		const { query: expectedQuery, value: valueQuery } = setPasswordMode;

		const expected = queryStore.query(expectedQuery);
		const value = queryStore.query(valueQuery);
		return expected === value;
	}

	@action
	async initialize(): Promise<void> {
		if (!this.isInitialized) {
			const { appStore, queryStore } = this.storeContext;
			const loadingKey = 'SetPasswordStore.initialize';
			appStore.startLoading(loadingKey);
			if (this.mustValidateToken) {
				const isValidToken = await this.validateToken();
				if (!isValidToken) {
					appStore.stopLoading(loadingKey);
					return;
				}
			}
			const { passwordValidationStore } = this.storeContext;
			await passwordValidationStore.initialize();

			const { context = {} } = queryStore;
			const response = {
				...(context.response || {}),
				passwordMode: this.modeKey,
			};
			queryStore.context = {
				...context,
				response,
			};
			queryStore.responseContext = response;

			if (this.modeKey === SetPasswordMode.expiration) {
				this.getPasswordText();
			}
			this.isInitialized = true;
			appStore.stopLoading(loadingKey);
		}
	}

	@action
	getPasswordText = async () => {
		const { kurtosysApiStore, messageStore, queryStore } = this.storeContext;
		let expirationText: string = '';
		try {
			const user = await kurtosysApiStore.getApiUserByToken.execute();
			const expirationTextQueryOptions = (this.configuration && this.configuration.expirationText) || DEFAULT_EXPIRATION_TEXT;
			expirationText = queryStore.query(expirationTextQueryOptions, { responseContext: user });
		}
		catch (ex) {
			expirationText = '';
		}
		messageStore.setInfoText(expirationText);
	}

	@computed
	get mustValidateToken(): boolean {
		return this.modeKey === SetPasswordMode.password || this.modeKey === SetPasswordMode.registration;
	}

	async validateToken() {
		const resetToken = this.resetToken;
		const { kurtosysApiStore, appStore } = this.storeContext;
		const overrideOptions = {
			body: {
				passwordRequestId: resetToken || '',
				type: this.modeKey,
			} as TValidateCodeRequestBody,
		};
		const validationResponse = await kurtosysApiStore.validateCode.callApi(overrideOptions);
		if (validationResponse.ok) {
			this.codeValidation = await validationResponse.json();
		}
		else if (validationResponse.status === 404) {
			this.codeValidation = await validationResponse.json();
		}
		else {
			this.codeValidation = undefined;
		}
		if (!this.codeValidation || this.codeValidation.error) {
			appStore.setStep(config.loginSteps.CODE_INVALID);
			return false;
		}
		return true;
	}

	@computed
	get defaultOptions() {
		return this.configuration && this.configuration.defaultOptions;
	}

	@computed
	get heading(): string | undefined {
		const { heading } = this.options;
		return heading && this.translationStore.translate(heading);
	}

	@computed
	get text(): string | undefined {
		const { text } = this.options;
		return text && this.translationStore.translate(text);
	}

	@computed
	get updatePasswordText(): string {
		return this.translationStore.translate(this.options.buttonText || 'Set Password');
	}

	@computed
	get passwordPlaceholderText(): string {
		return this.translationStore.translate(this.options.passwordPlaceholder || 'Password');
	}

	@computed
	get passwordLabelText(): string | undefined {
		const { passwordLabel } = this.options;
		return passwordLabel && this.translationStore.translate(passwordLabel);
	}

	@computed
	get confirmPasswordPlaceholderText(): string {
		return this.translationStore.translate(this.options.confirmPasswordPlaceholder || 'Confirm Password');
	}

	@computed
	get confirmPasswordLabelText(): string | undefined {
		const { confirmPasswordLabel } = this.options;
		return confirmPasswordLabel && this.translationStore.translate(confirmPasswordLabel);
	}

	@computed
	get resetToken() {
		const type = utils.url.getQueryStringValue({ key: 'type' }, window.location.search);

		if (type === 'self-registration') {
			return this.registrationConfirmationToken;
		}
		const token = utils.url.getQueryStringValue({ key: 'token' }, window.location.search);
		return token;
	}

	@action
	setPassword = (value: string) => {
		this.password = value;
		const { passwordValidationStore } = this.storeContext;
		passwordValidationStore.password = this.password;
		this.isValid = passwordValidationStore.isValid;
	}

	@action
	setConfirmPassword = (value: string) => {
		this.confirmPassword = value;
		const { passwordValidationStore } = this.storeContext;
		passwordValidationStore.confirmPassword = this.confirmPassword;
		this.isValid = passwordValidationStore.isValid;
	}

	@computed
	get oldPasswordNotAllowedText() {
		return this.translationStore.translate((this.configuration && this.configuration.oldPasswordNotAllowedText) || 'Old passwords are not valid');
	}

	@computed
	get setPasswordFailedText() {
		return this.translationStore.translate((this.configuration && this.configuration.setPasswordFailedText) || 'Set password has failed');
	}

	@computed
	get mfaEnabled(): boolean {
		return !!(this.codeValidation && Array.isArray(this.codeValidation.enabled2FAMethods) && this.codeValidation.enabled2FAMethods.length > 0);
	}

	@computed
	get messageRequired(): boolean {
		return !!(this.codeValidation && this.codeValidation.messageRequired);
	}

	@computed
	get imageRequired(): boolean {
		return !!(this.codeValidation && this.codeValidation.imageRequired);
	}

	@action
	handleUpdateClick = () => {
		const { appStore, setupAssurancesStore } = this.storeContext;
		if (this.modeKey === SetPasswordMode.registration && (this.imageRequired || this.messageRequired)) {

			appStore.setStep(config.loginSteps.SETUP_ASSURANCES);
		}
		else {
			this.updatePassword();
		}
	}

	@action
	updatePassword = async (includeAssurances: boolean = false) => {
		if (this.isValid) {
			const { kurtosysApiStore, appStore, messageStore, passwordValidationStore, mfaSetupStore } = this.storeContext;
			const loadingKey = 'SetPasswordStore.onUpdatePassword';
			appStore.startLoading(loadingKey);
			const overrideOptions: Partial<IRequestOptions<IUpdatePasswordRequest>> = {
				body: {
					passwordRequestId: this.resetToken as string,
					password: this.password,
					confirmPassword: this.confirmPassword,
				},
			};
			if (includeAssurances && overrideOptions.body) {
				const { setupAssurancesStore } = this.storeContext;
				overrideOptions.body.message = this.messageRequired ? setupAssurancesStore.message : undefined;
				overrideOptions.body.imageId = this.imageRequired && setupAssurancesStore.selectedImage ? setupAssurancesStore.selectedImage.id : undefined;
			}
			const response = await kurtosysApiStore.updatePassword.callApi(overrideOptions);
			if (response.status === 200) {
				const { redirectOnSuccess } = this.options;
				if (redirectOnSuccess) {
					appStore.redirectTo(appStore.redirectURL);
				}
				else if (this.mfaEnabled) {
					appStore.setStep(config.loginSteps.MFA);
					mfaSetupStore.initialize();
				}
				else {
					appStore.setStep(config.loginSteps.USER_SUCCESS);
				}
			}
			else {
				if (response.status === 409) {
					this.password = '';
					passwordValidationStore.password = this.password;
					this.isValid = passwordValidationStore.isValid;
					messageStore.setErrorText(this.oldPasswordNotAllowedText);
				}
				else {
					messageStore.setErrorText(this.setPasswordFailedText);
				}
			}
			appStore.stopLoading(loadingKey);
		}
	}
}