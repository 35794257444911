import React, { Component, Fragment } from 'react';
import { ILoginCodeProps, ILoginCodeState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import InfoMessage from '../App/styledComponents/InfoMessage';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import Loading from '../shared/Loading';
import IconInput from '../App/styledComponents/IconInput';
import Label from './styledComponents/Label';

@inject('appStore', 'loginCodeStore', 'messageStore')
@observer
export class LoginCode extends Component<ILoginCodeProps, ILoginCodeState> {
	static configurationKey: 'loginCode' = 'loginCode';
	static styleKey: 'loginCode' = 'loginCode';
	constructor(props: ILoginCodeProps) {
		super(props);
	}
	render() {
		const { className, loginCodeStore, messageStore, appStore } = this.props;

		if (!loginCodeStore || !messageStore) {
			return null;
		}

		return (
			<Wrapper className={className}>
				<InfoMessage>{loginCodeStore.titleText}</InfoMessage>

				{loginCodeStore.textVerifyProcessing ? (
					<Loading />
				) : (
					<Fragment>
						<form
							noValidate={appStore && appStore.disableBrowserValidation}
							spellCheck={false}
							autoSave="false"
							onSubmit={(e) => {
								e.preventDefault();
								return false;
							}}
						>
							{loginCodeStore.labelText && (
								<Label htmlFor="loginCodeInput" value={loginCodeStore.labelText} />
							)}
							<IconInput
								id="loginCodeInput"
								name="loginCode"
								aria-label={
									!loginCodeStore.labelText && loginCodeStore.placeholderText
								}
								placeholder={loginCodeStore.placeholderText}
								type="text"
								autoFocus
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									loginCodeStore.setConfirmationCode(event.target.value)
								}
								value={loginCodeStore.confirmationCode}
								iconProps={{ asset: 'base.iconInput.shield' }}
								hasError={messageStore.hasErrorText}
								pattern="[0-9]*"
								onKeyDown={() => {
									loginCodeStore.confirmTextCode();
								}}
								data-qa-ref="loginCodeInput"
								required
								aria-required="true"
								autoComplete="one-time-code"
							/>
							<ButtonRaised onClick={loginCodeStore.confirmTextCode} width="55%">
								{loginCodeStore.confirmButtonText}
							</ButtonRaised>
							<ButtonRaised
								onClick={() => {
									loginCodeStore.resendCode();
								}}
								width="55%"
							>
								{loginCodeStore.resendButtonText}
							</ButtonRaised>
							<ErrorMessage>{messageStore.errorText}</ErrorMessage>
						</form>
					</Fragment>
				)}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(LoginCode, 'loginCode');
