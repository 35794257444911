import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ILoginStrategy, ILoginStrategySelectorConfiguration } from '../models';
import { config } from '../../App/models/config';
import { TMfaType } from '../../../models/commonTypes';

export class LoginStrategySelectorStore extends StoreBase {
	static componentKey: 'loginStrategySelector' = 'loginStrategySelector';
	@observable public loginStrategies: any[] = [];
	@observable public showtextEntry2FA: boolean = false;
	@observable public confirmationCode: string = '';

	@computed
	get configuration(): ILoginStrategySelectorConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(LoginStrategySelectorStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> { }

	public getLoginStrategies = async () => {
		const { appStore, kurtosysApiStore, messageStore } = this.storeContext;
		const loadingKey = 'LoginStrategySelector.getLoginStrategies';
		appStore.startLoading(loadingKey);
		try {
			// TODO: Remove - Used for testing UI for now
			// const strategies = [{ 'type': 'local', 'name': 'Login with username and password' }, { 'name': 'saml sp config test', 'type': 'SAML SP', 'applicationCode': 'kurtosysapp' }];
			const { explicitStep } = appStore;
			if (!explicitStep) {
				// Since we are using the proxy, we don't need to specify the organization name in the overrides body
				const strategies = await kurtosysApiStore.requestStrategies.execute();
				if (strategies.length === 1) {
					appStore.setStep(config.loginSteps.USER_LOGIN);
				} else {
					this.loginStrategies = strategies;
					appStore.setStep(config.loginSteps.LOGIN_STRATEGY_SELECTOR);
				}
			}
		} catch (e) {
			messageStore.setErrorText('An error occurred whilst retrieving your login options');
		}
		appStore.stopLoading(loadingKey);
	};

	@computed
	get hasLoginStrategies() {
		return this.loginStrategies && this.loginStrategies.length > 0;
	}

	public getStrategyButtonName = (type: string, name: string) => {
		return `${ type } (${ name })`.toUpperCase();
	};

	@action
	public onAuthMethodSelect = async (strategy: ILoginStrategy) => {
		const { kurtosysApiStore, appStore, messageStore, userLogin2FaStore, loginCodeStore } = this.storeContext;
		messageStore.setErrorText('');
		if (strategy.type === 'local') {
			appStore.setStep(config.loginSteps.USER_LOGIN);
		} else if (strategy.type === 'PUSH' || strategy.type === 'CALL') {
			const loadingKey = 'LoginStrategySelector.onAuthMethodSelectPushOrCall';
			appStore.startLoading(loadingKey);
			const overrideOptions = {
				body: { type: strategy.type },
			};
			const response = await kurtosysApiStore.login2FA.callApi(overrideOptions);
			appStore.stopLoading(loadingKey);
			if (response.status === 200) {
				await appStore.refreshRedirectUrl();
				return appStore.redirectTo(appStore.redirectURL);
			}
			messageStore.setErrorText('Error authenticating device');
		} else if (strategy.type === 'TEXT') {
			const loadingKey = 'LoginStrategySelector.onAuthMethodSelectText';
			appStore.startLoading(loadingKey);
			const body: any = {
				type: strategy.type,
			};
			if (loginCodeStore.confirmationCode) {
				body.passcode = loginCodeStore.confirmationCode;
			}
			const overrideOptions = {
				body,
			};
			const response = await kurtosysApiStore.login2FA.callApi(overrideOptions);
			appStore.stopLoading(loadingKey);
			userLogin2FaStore.showtextEntry2FA = true;
			userLogin2FaStore.hasSentCodeOnce = false;
			if (response.status !== 200) {
				messageStore.setErrorText('Error sending SMS to device');
			}
		} else if (strategy.type === 'PASSCODE') {
			const loadingKey = 'LoginStrategySelector.onAuthMethodSelectPasscode';
			appStore.startLoading(loadingKey);
			const overrideOptions = {
				disableCachableRequests: true,
				body: {
					type: 'TEXT' as TMfaType,
					passcode: loginCodeStore.confirmationCode,
				},
			};
			const response = await kurtosysApiStore.login2FA.callApi(overrideOptions);
			appStore.stopLoading(loadingKey);
			userLogin2FaStore.showtextEntry2FA = true;
			if (response.status === 200) {
				await appStore.refreshRedirectUrl();
				return appStore.redirectTo(appStore.redirectURL);
			}
			messageStore.setErrorText('Error authenticating device - invalid passcode');
		} else if (strategy.type !== 'local' && strategy.name) {
			const overrideOptions = {
				disableCachableRequests: true,
				placeholders: {
					strategyName: strategy.callbackIdentifier || strategy.name,
				},
				queryString: {
					redirect: appStore.redirectURL,
				},
				headers: {
					'X-KSYS-APP': (strategy as any).applicationCode,
				},
			};

			const response = await kurtosysApiStore.samlSignIn.execute(overrideOptions);
			window.location.href = response;
		}
	};
}
