import React, { Component, Fragment } from 'react';
import { IRegistrationProps, IRegistrationState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import { config } from '../App/models/config';
import { models } from '@kurtosys/ksys-app-template';
import IconInput from '../App/styledComponents/IconInput';
import Checkbox from '../App/styledComponents/Checkbox';
import Label from '../UserLogin/styledComponents/Label';
import Dropdown from '@kurtosys/ksys-app-components/dist/components/base/Dropdown';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models';
import { Loading } from '../shared/Loading';
import InfoMessage from '../App/styledComponents/InfoMessage';
import { RegistrationStore } from './stores/RegistrationStore';

@inject('appStore', 'messageStore', 'registrationStore')
@observer
export class Registration extends Component<IRegistrationProps, IRegistrationState> {
	[x: string]: any;
	static configurationKey: 'registration' = 'registration';
	static styleKey: 'registration' = 'registration';
	constructor(props: IRegistrationProps) {
		super(props);
		const { registrationStore } = props;
		if (registrationStore && !registrationStore.isInitialized) {
			registrationStore.initialize();
		}
	}

	renderText(property: models.api.auth.userMetaProperties, registrationStore: RegistrationStore) {
		if (property.dataType !== 'text') {
			return null;
		}
		return (
			<Fragment>
				<Label htmlFor={property.label} value={property.label} />
				<IconInput
					id={property.label}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						registrationStore.updateTextValues(event.target.value, property.code)
					}
					onKeyDown={registrationStore.validateFields}
					value={registrationStore.value(property.code)}
					hasError={registrationStore.hasError(property.code)}
					required={property.required}
					aria-required={property.required}
					aria-label={property.label}
				></IconInput>
			</Fragment>
		);
	}

	renderComposite(
		property: models.api.auth.userMetaProperties,
		registrationStore: RegistrationStore,
	) {
		if (property.dataType !== 'composite') {
			return null;
		}
		return (
			<Fragment>
				<Label htmlFor={property.label} value={property.label} readOnly />
				<IconInput
					id={property.label}
					value={registrationStore.value(property.code)}
					hasError={registrationStore.hasError(property.code)}
					required={property.required}
					aria-required={property.required}
					aria-label={property.label}
					readOnly
				></IconInput>
			</Fragment>
		);
	}

	renderEmail(
		property: models.api.auth.userMetaProperties,
		registrationStore: RegistrationStore,
	) {
		if (property.dataType !== 'email') {
			return null;
		}
		return (
			<Fragment>
				<Label htmlFor={property.label} value={property.label} />
				<IconInput
					id={property.label}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						registrationStore.updateTextValues(event.target.value, property.code)
					}
					onKeyDown={registrationStore.validateFields}
					value={registrationStore.value(property.code)}
					hasError={registrationStore.hasError(property.code)}
					required={property.required}
					aria-required={property.required}
					aria-label={property.label}
					type={property.dataType}
					ref={registrationStore.values[property.code].ref}
				></IconInput>
			</Fragment>
		);
	}

	renderEnum(property: models.api.auth.userMetaProperties, registrationStore: RegistrationStore) {
		if (property.dataType !== 'enum') {
			return null;
		}
		return (
			<Dropdown
				label={property.label}
				items={property.options as IDropdownItem[]}
				onChange={(items: IDropdownItem | IDropdownItem[]) => {
					registrationStore.updateEnumValues(items as IDropdownItem, property.code);
				}}
				selectedItem={{
					label: registrationStore.value(property.code),
					value: registrationStore.value(property.code),
				}}
			></Dropdown>
		);
	}

	renderBoolean(
		property: models.api.auth.userMetaProperties,
		registrationStore: RegistrationStore,
	) {
		if (property.dataType !== 'boolean') {
			return null;
		}
		return (
			<Fragment>
				<Checkbox
					id={property.label}
					label={property.label}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						registrationStore.updateBooleanValues(property.code)
					}
					hasError={registrationStore.hasError(property.code)}
					value={registrationStore.value(property.code) === 'true'}
					required={property.required}
					aria-required={property.required}
					aria-label={property.label}
				></Checkbox>
			</Fragment>
		);
	}

	render() {
		const { className, registrationStore, appStore, messageStore } = this.props;
		const { loginSteps } = config;
		const { REGISTER_USER } = loginSteps;
		if (!registrationStore) {
			return null;
		}
		return (
			<Wrapper className={className}>
				{!registrationStore.responseReceived &&
					appStore &&
					appStore.currentStep === REGISTER_USER && (
						<form
							noValidate={appStore && appStore.disableBrowserValidation}
							spellCheck={false}
							autoSave="false"
							onSubmit={(e) => {
								e.preventDefault();
								return false;
							}}
						>
							{registrationStore &&
								Object.keys(registrationStore.values).length > 0 &&
								registrationStore
									.filterUserMetaPropertyDictionary(
										(property) => !property.hidden,
									)
									.map((property) => {
										return (
											<Fragment>
												{this.renderText(property, registrationStore)}
												{this.renderComposite(property, registrationStore)}
												{this.renderEmail(property, registrationStore)}
												{this.renderEnum(property, registrationStore)}
												{this.renderBoolean(property, registrationStore)}
											</Fragment>
										);
									})}
							<ErrorMessage>{messageStore && messageStore.errorText}</ErrorMessage>
							<ButtonRaised
								onClick={registrationStore && registrationStore.signUpClick}
							>
								{registrationStore && registrationStore.registrationButtonText}
							</ButtonRaised>
						</form>
					)}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Registration, 'registration');
