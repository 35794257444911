import React, { Component, Fragment } from 'react';
import { IUserLoginProps, IUserLoginState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ForgotLinksWrapper from './styledComponents/ForgotLinksWrapper';
import RegistrationLink from './styledComponents/RegistrationLink';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import ButtonFlat from '../App/styledComponents/ButtonFlat';
import ButtonText from '../App/styledComponents/ButtonText';
import IconInput from '../App/styledComponents/IconInput';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import { Assurance } from '../Assurance/Assurance';
import { utils } from '@kurtosys/ksys-app-template';
import Label from './styledComponents/Label';

@inject(
	'appStore',
	'userLoginStore',
	'messageStore',
	'assuranceStore',
	'loginStrategySelectorStore',
)
@observer
export class UserLogin extends Component<IUserLoginProps, IUserLoginState> {
	static configurationKey: 'userLogin' = 'userLogin';
	static styleKey: 'userLogin' = 'userLogin';
	constructor(props: IUserLoginProps) {
		super(props);
	}
	render() {
		const {
			className,
			userLoginStore,
			appStore,
			messageStore,
			assuranceStore,
			loginStrategySelectorStore,
		} = this.props;

		if (
			!userLoginStore ||
			!appStore ||
			!messageStore ||
			!assuranceStore ||
			!loginStrategySelectorStore
		) {
			return null;
		}

		const { hasLoginStrategies } = loginStrategySelectorStore;
		return (
			<Wrapper className={className}>
				{hasLoginStrategies && (
					<ButtonFlat
						onClick={appStore.clear}
						data-qa-ref="loginBackButton"
						iconProps={{ asset: 'base.icons.backArrow' }}
						iconPosition={'left'}
					>
						{userLoginStore.backButtonText}
					</ButtonFlat>
				)}
				<form
					noValidate={appStore && appStore.disableBrowserValidation}
					spellCheck={false}
					autoSave="false"
					onSubmit={(e) => {
						e.preventDefault();
						return false;
					}}
				>
					{userLoginStore.usernameLabelText && (
						<Label htmlFor="usernameInput" value={userLoginStore.usernameLabelText} />
					)}
					<IconInput
						id="usernameInput"
						name="username"
						aria-label={
							!userLoginStore.usernameLabelText &&
							userLoginStore.usernamePlaceholderText
						}
						placeholder={userLoginStore.usernamePlaceholderText}
						autoFocus
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							userLoginStore.updateUsername(event.target.value)
						}
						value={userLoginStore.username}
						onBlur={userLoginStore.checkUsernameForAssurance}
						iconProps={{ asset: 'base.iconInput.personOutline' }}
						hasError={messageStore.hasErrorText}
						onKeyDown={() => {
							userLoginStore.checkUsernameForAssurance();
							userLoginStore.validateUserLogin();
						}}
						data-qa-ref="usernameInput"
						required
						aria-required="true"
						autoComplete="username"
					/>
					{assuranceStore.showAssuranceBox && (
						<Assurance {...assuranceStore.assuranceProps} />
					)}
					{userLoginStore.passwordLabelText && (
						<Label htmlFor="passwordInput" value={userLoginStore.passwordLabelText} />
					)}
					<IconInput
						id="passwordInput"
						name="password"
						aria-label={
							!userLoginStore.passwordLabelText &&
							userLoginStore.passwordPlaceholderText
						}
						placeholder={userLoginStore.passwordPlaceholderText}
						type="password"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							userLoginStore.updatePassword(event.target.value)
						}
						value={userLoginStore.password}
						iconProps={{ asset: 'base.iconInput.fingerPrint' }}
						hasError={messageStore.hasErrorText}
						onKeyDown={userLoginStore.validateUserLogin}
						data-qa-ref="passwordInput"
						required="required"
						aria-required="true"
						autoComplete="current-password"
					/>
					<ErrorMessage data-qa-ref="loginErrorMessage">
						{messageStore.errorText}
					</ErrorMessage>
					<ButtonRaised
						onClick={userLoginStore.validateUserLogin}
						data-qa-ref="loginButton"
					>
						{userLoginStore.loginButtonText}
					</ButtonRaised>
					{userLoginStore.showRegistrationButton && (
						<Fragment>
							{userLoginStore.registrationButtonLabel && (
								<Label>{userLoginStore.registrationButtonLabel}</Label>
							)}
							<ButtonRaised onClick={userLoginStore.registerClick}>
								{userLoginStore.registrationButtonText}
							</ButtonRaised>
						</Fragment>
					)}
				</form>
				<ForgotLinksWrapper>
					{appStore.isActionEnabled('forgot-username') && (
						<ButtonText
							onClick={userLoginStore.onForgotUsername}
							data-qa-ref="forgotUsernameButton"
						>
							{userLoginStore.forgotUsernameButtonText}
						</ButtonText>
					)}
					{appStore.isActionEnabled('forgot-password') && (
						<ButtonText
							onClick={userLoginStore.onForgotPassword}
							data-qa-ref="forgotPasswordButton"
						>
							{userLoginStore.forgotPasswordButtonText}
						</ButtonText>
					)}
					{userLoginStore.showRegistrationLink && (
						<ButtonText onClick={userLoginStore.setRegisterStep}>
							{userLoginStore.registrationLinkText}
						</ButtonText>
					)}
				</ForgotLinksWrapper>
				{!utils.typeChecks.isNullOrEmpty(userLoginStore.registrationText) && (
					<RegistrationLink href={userLoginStore.registrationUrl}>
						{userLoginStore.registrationText}
					</RegistrationLink>
				)}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(UserLogin, 'userLogin');
