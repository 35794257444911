import { models, utils } from '@kurtosys/ksys-app-template';
import { IButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/Button/models/IButtonProps';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models';
import { computed, action, observable } from 'mobx';
import React from 'react';
import { StoreBase } from '../../../common/StoreBase';
import { getApplicationCode } from '../../../start';
import { IRegistrationConfiguration, IRegistrationFieldValue } from '../models';
import { config } from '../../App/models/config';
import { TLoginStep } from '../../../models/app/TLoginStep';

export class RegistrationStore extends StoreBase {
	[x: string]: any;
	static componentKey: 'registration' = 'registration';

	@observable strategyConfig: models.api.auth.IRetrieveStrategyConfigResponse | undefined;
	@observable errorMessages: string[] = [];
	@observable isValidated: boolean = false;
	@observable isInitialized: boolean = false;
	@observable responseReceived: boolean = false;
	@observable.ref values: Record<string, IRegistrationFieldValue> = {};
	registerResponse: string = '';

	@computed
	get configuration(): IRegistrationConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(RegistrationStore.componentKey);
		}
	}

	@computed
	get rawUserMetaProperties(): models.api.auth.userMetaProperties[] | undefined {
		return this.strategyConfig && this.strategyConfig.userMetaProperties;
	}

	@computed
	get userMetaPropertyDictionary(): Record<string, models.api.auth.userMetaProperties> {
		if (!this.rawUserMetaProperties) {
			return {};
		}
		return this.rawUserMetaProperties.reduce((properties: Record<string, models.api.auth.userMetaProperties>, property: models.api.auth.userMetaProperties) => {
			properties[property.code] = {
				...property,
				label: property.required === true ? `${property.label} *` : property.label,
				dataType: property.dataType.toLowerCase() as models.api.auth.TUserMetaDataType,
			};
			return properties;
		}, {});
	}

	@computed
	get registrationButtonText(): string {
		return (this.configuration && this.registrationButton && this.registrationButton.text) || 'Register User';
	}

	@computed
	get useAnalytics(): boolean {
		return (this.configuration && this.configuration.analyticsSupport) || false;
	}

	@computed
	get registrationButton(): IButtonProps | undefined {
		return this.registrationConfiguration && this.registrationConfiguration.buttonProps;
	}

	@computed
	get registrationConfiguration(): IRegistrationConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(RegistrationStore.componentKey);
		}
	}

	public updateTextValues = (value: string, code: string) => {
		const { messageStore } = this.storeContext;
		this.updateValue(code, value);
		messageStore.clearAll();
	}

	public updateBooleanValues = (code: string) => {
		const { messageStore } = this.storeContext;
		if (this.values[code] && this.values[code].value) {
			this.updateValue(code, this.values[code].value === 'true' ? 'false' : 'true');
		}
		else {
			this.updateValue(code, 'true');
		}
		messageStore.clearAll();
	}

	updateEnumValues = (items: IDropdownItem, code: string) => {
		const { messageStore } = this.storeContext;
		this.updateValue(code, items.value);
		messageStore.clearAll();
	}

	@action
	updateValue(code: string, value: string) {
		let fields = { ...this.values };
		// update target field's value
		fields[code] = { ...fields[code], value };
		fields = this.updateCompositeFields(fields);
		this.values = fields;
	}

	@action
	updateCompositeFields(updatedFields: Record<string, IRegistrationFieldValue>, options: { applyDefaults?: boolean } = {}) {
		const { applyDefaults = false } = options;
		const nonCompositeFieldValues = this.filterUserMetaPropertyDictionary(property => property.dataType !== 'composite').reduce((fieldValues: Record<string, string>, property) => {
			if (updatedFields[property.code] && updatedFields[property.code].value) {
				const value = updatedFields[property.code].value;
				let workingValue;
				let skipWarning = false;
				switch (typeof value) {
					case 'string':
						workingValue = value;
						break;
					case 'boolean':
					case 'number':
						workingValue = (value as Boolean | Number).toString();
						break;
					case 'undefined':
						skipWarning = true;
					default:
						if (!skipWarning) {
							console.warn(`invalid composite field placeholder {${property.code}} type`, typeof value);
						}
						workingValue = '';
				}
				fieldValues[property.code] = workingValue;
			}
			return fieldValues;
		}, {});
		return Object.keys(updatedFields).reduce((updated: Record<string, IRegistrationFieldValue>, code: string) => {
			const field: IRegistrationFieldValue = { ...updatedFields[code] };
			const meta = this.userMetaPropertyDictionary[code];
			if (meta.dataType === 'composite' && meta.template) {
				const replaced = utils.replacePlaceholders({
					text: meta.template,
					values: nonCompositeFieldValues,
				});
				field.value = replaced.trim();
				if (applyDefaults && meta.default && utils.typeChecks.isNullOrEmpty(field.value)) {
					field.value = meta.default;
				}
			}
			updated[code] = field;
			return updated;
		}, {});
	}

	@action
	async initialize(): Promise<void> {
		const { appStore, messageStore } = this.storeContext;
		const loadingKey = 'RegistrationStore.retrieveStrategyConfig';
		messageStore.clearAll();
		appStore.startLoading(loadingKey);
		await this.retrieveStrategyConfig();
		this.getBaseValues();
		this.isInitialized = true;
	}

	filterUserMetaPropertyDictionary(cb: (property: models.api.auth.userMetaProperties) => boolean): models.api.auth.userMetaProperties[] {
		return Object.keys(this.userMetaPropertyDictionary).reduce((properties: models.api.auth.userMetaProperties[], code: string) => {
			const property = { ...this.userMetaPropertyDictionary[code] };
			if (cb(property)) {
				properties.push(property);
			}
			return properties;
		}, []);
	}

	mapUserMetaPropertyDictionary<TReturnType>(cb: (property: models.api.auth.userMetaProperties) => TReturnType): TReturnType[] {
		return Object.keys(this.userMetaPropertyDictionary).map(code => cb({ ...this.userMetaPropertyDictionary[code] }));
	}

	loopUserMetaPropertyDictionary(cb: (property: models.api.auth.userMetaProperties) => void): void {
		for (const code in this.userMetaPropertyDictionary) {
			cb(this.userMetaPropertyDictionary[code]);
		}
	}

	@action
	getBaseValues = () => {
		let values: Record<string, IRegistrationFieldValue> = {};
		this.loopUserMetaPropertyDictionary((property) => {
			values[property.code] = {
				value: property.default || '',
				ref: React.createRef(),
			};
		});
		values = this.updateCompositeFields(values, { applyDefaults: true });
		this.values = values;
	}

	@action
	async retrieveStrategyConfig(): Promise<void> {
		const { kurtosysApiStore, appStore } = this.storeContext;
		const options: Partial<models.api.common.IRequestOptions<models.api.auth.IRetrieveStrategyConfigRequest>> = {
			body: {
				applicationCode: getApplicationCode() || '',
				type: 'Self Registration',
			},
		};
		this.strategyConfig = await kurtosysApiStore.retrieveStrategyConfig.execute(options);
		const loadingKey = 'RegistrationStore.retrieveStrategyConfig';
		appStore.stopLoading(loadingKey);
	}

	@action
	public logAnalytics = (event: string, eventType: string, status?: string) => {
		if (this.storeContext && this.storeContext.appStore) {
			const analytics = this.storeContext.appStore.analyticsHelper.logEvent({
				event,
				eventType,
				context: {
					...this.storeContext.appStore.getDefaultAnalyticsContext(),
					status,
				},
			});
		}
	}

	@action
	public validateFields = () => {
		const { messageStore } = this.storeContext;

		const values: Record<string, IRegistrationFieldValue> = { ...this.values };

		this.loopUserMetaPropertyDictionary((item) => {
			if (!item.hidden) {
				const hasValue = !!(values[item.code] && values[item.code].value);
				const inputValue = (values && values[item.code] && values[item.code].value) || '';
				values[item.code] = { ...values[item.code], validationMessage: undefined };

				switch (item.dataType) {
					case 'email':
						// my.user-name.test1-test2+alias@my.amazing-domain.co.za
						const regex = /^\w+([\.\-]?\w+){0,}([\+]?\w+)*@\w+([\.\-]?\w+){0,}.*(\.\w{2,})+$/;
						if (inputValue.length > 0) {
							if (inputValue.length > 64) {
								values[item.code] = {
									...values[item.code],
									validationMessage: `${item.label} field Max Length: 64`,
								};
							}
							else if (regex.exec(inputValue) === null) {
								values[item.code] = {
									...values[item.code],
									validationMessage: 'Not a valid email',
								};
							}
						}
						else if (item.required && inputValue.length === 0) {
							values[item.code] = {
								...values[item.code],
								validationMessage: `${item.label} field is mandatory`,
							};
						}
						break;
					case 'composite':
					case 'text':
						if (inputValue.trim().length < 1 && item.required) {
							values[item.code] = {
								...values[item.code],
								validationMessage: `${item.label} field is mandatory`,
							};
						}
						break;
					case 'enum':
						if (!hasValue && item.required) {
							values[item.code] = {
								...values[item.code],
								validationMessage: `${item.label} field is mandatory`,
							};
						}
						break;
					case 'boolean':
						if ((!hasValue || inputValue === 'false') && item.required) {
							values[item.code] = {
								...values[item.code],
								validationMessage: `${item.code} field is mandatory`,
							};
						}
						break;
				}
			}
		});

		this.values = values;
		this.errorMessages = [];
		let setFocusKey;

		Object.keys(values).forEach((key) => {
			if (values[key].validationMessage) {
				if (this.errorMessages.length === 0) {
					setFocusKey = key;
				}
				this.errorMessages.push(values[key].validationMessage as string);
			}
		});

		setFocusKey && values[setFocusKey].ref && values[setFocusKey].ref.current && values[setFocusKey].ref.current.focus();

		messageStore.clearAll();
		if (this.errorMessages.length > 0) {
			messageStore.setErrorText(this.errorMessages[0]);
			this.isValidated = false;
		}
		else {
			this.isValidated = true;
		}
	}

	@action
	public signUpClick = async () => {
		this.useAnalytics && this.logAnalytics('self-registration-request', 'userActioned');
		const { messageStore, appStore } = this.storeContext;
		let analyticsResponse = '';
		this.validateFields();

		if (this.isValidated) {
			this.registerResponse = (await this.registerNewUser()).message;
			let nextStep: TLoginStep;
			analyticsResponse = 'success';
			switch (this.registerResponse) {
				case 'New user request successful, check your email for further instructions':
					nextStep = config.loginSteps.NEW_USER_REGISTERED;
					break;
				case 'New user request successful, registration email has been suppressed':
					nextStep = config.loginSteps.NEW_USER_REGISTERED_EMAIL_SUP;
					break;
				default:
					nextStep = config.loginSteps.ERROR;
					analyticsResponse = 'failed';
					break;
			}
			this.useAnalytics && this.logAnalytics('self-registration-response', 'systemActioned', analyticsResponse);
			const loadingKey = 'RegistrationStore.registerNewUser';
			appStore.stopLoading(loadingKey);
			appStore.setStep(nextStep);
		}
	}

	@action
	async registerNewUser(): Promise<models.api.auth.IRegisterNewUserResponse> {
		const { kurtosysApiStore, appStore } = this.storeContext;
		const loadingKey = 'RegistrationStore.registerNewUser';
		appStore.startLoading(loadingKey);

		const userMeta: {
			[key: string]: string;
		} = {};

		Object.keys(this.values).map((item) => {
			userMeta[item] = this.values[item].value as string;
		});

		const options: Partial<models.api.common.IRequestOptions<models.api.auth.IRegisterNewUserRequest>> = {
			body: {
				userMeta,
				applicationCode: getApplicationCode() || '',
				type: (this.strategyConfig && this.strategyConfig.type) || '',
				name: (this.strategyConfig && this.strategyConfig.name) || '',
			},
		};

		return await kurtosysApiStore.registerNewUser.execute(options);
	}

	@action
	hasError(code: string): boolean {
		const value = this.values[code];
		if (!value || !value.validationMessage || value.validationMessage === '') {
			return false;
		}
		return true;
	}

	@action
	value(code: string): string {
		return this.values[code].value || '';
	}
}
