import React, { Component } from 'react';
import {
	IRegistrationConfirmationProps,
	IRegistrationConfirmationState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Icon from '@kurtosys/ksys-app-components/dist/components/base/Icon/Icon';
import Loading from '../shared/Loading';
import InfoMessage from './styledComponents/InfoMessage';
import ButtonFlat from '../App/styledComponents/ButtonFlat';

@inject('appStore', 'registrationConfirmationStore')
@observer
export class RegistrationConfirmation extends Component<
IRegistrationConfirmationProps,
IRegistrationConfirmationState
> {
	static configurationKey: 'registrationConfirmation' =
		'registrationConfirmation';
	static styleKey: 'registrationConfirmation' = 'registrationConfirmation';
	constructor(props: IRegistrationConfirmationProps) {
		super(props);
		const { registrationConfirmationStore } = props;
		if (
			registrationConfirmationStore &&
			!registrationConfirmationStore.isInitialized
		) {
			registrationConfirmationStore.initialize();
		}
	}
	render() {
		const { className, registrationConfirmationStore } = this.props;

		if (!registrationConfirmationStore) {
			return null;
		}
		const { buttonProps, confirmRegistration, buttonClicked, confirmationLandingText, confirmationStatusText } = registrationConfirmationStore;

		return (
			<Wrapper className={ className }>
				{ registrationConfirmationStore.isLoading && <Loading /> }
				{
					confirmationLandingText && !buttonClicked &&
					<InfoMessage>
						{ confirmationLandingText }
					</InfoMessage>
				}
				<ButtonFlat onClick={ confirmRegistration } { ...buttonProps }>
					{ buttonProps.text }
				</ButtonFlat>
				{
					confirmationStatusText && buttonClicked &&
					<InfoMessage>
						{ confirmationStatusText }
					</InfoMessage>
				}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(
	RegistrationConfirmation,
	'registrationConfirmation'
);
