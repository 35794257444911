import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { IButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/Button/models';
import { models } from '@kurtosys/ksys-app-template';
import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { getApplicationCode } from '../../../start';
import { config } from '../../App/models/config';
import { IRegistrationConfirmationConfiguration } from '../models';

export class RegistrationConfirmationStore extends StoreBase {
	static componentKey: 'registrationConfirmation' = 'registrationConfirmation';
	@observable isInitialized: boolean = false;
	@observable isLoading: boolean = false;
	@observable buttonClicked: boolean = false;

	@computed
	get configuration(): IRegistrationConfirmationConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(RegistrationConfirmationStore.componentKey);
		}
	}

	@computed
	get confirmationStatusText(): string | undefined {
		const value = this.getQueryValue(this.configuration && this.configuration.confirmationStatusText);
		return value;
	}

	@computed
	get confirmationLandingText(): string | undefined {
		const value = this.getQueryValue(this.configuration && this.configuration.confirmationLandingText);
		return value;
	}
	
	@computed
	get buttonProps(): IButtonProps {
		return this.configuration && this.configuration.buttonOptions || {};
	}

	@action
	async initialize(): Promise<void> {
	}

	@action
	confirmRegistration = async (): Promise<void> => {
		this.isLoading = true;
		this.buttonClicked = true;
		this.createSelfRegistrationUser();
		this.isInitialized = true;
	}
	
	@action
	async createSelfRegistrationUser(): Promise<void> {
		const { kurtosysApiStore, setPasswordStore, userLoginStore, appStore } = this.storeContext;
		const options: Partial<IRequestOptions<models.api.auth.ICreateSelfRegistrationUserRequest>> = {
			body: {
				token: appStore.registrationConfirmationToken || ''
			}
		}
		const rawResponse = await kurtosysApiStore.createSelfRegistrationUser.callApi(options);

		if (rawResponse.status === 200) {
			let response = await kurtosysApiStore.createSelfRegistrationUser.handleResponse(rawResponse);
			if (kurtosysApiStore.createSelfRegistrationUser.orchestrateResponse) {
				response = kurtosysApiStore.createSelfRegistrationUser.orchestrateResponse(response);
			}
			const registrationStatus = response.registrationStatus;
			const passwordResetToken = response.passwordResetToken || '';

			if (registrationStatus === 'created') {
				setPasswordStore.registrationConfirmationToken = passwordResetToken;
				setPasswordStore.initialize();
				appStore.setStep(config.loginSteps.SET_PASSWORD);
			} else if (registrationStatus === 'merged') {
				userLoginStore.initialize();
				appStore.setStep(config.loginSteps.USER_MERGED);
			}
		} else if (rawResponse.status === 404) {
			appStore.setStep(config.loginSteps.CODE_INVALID);
		} else {
			appStore.setStep(config.loginSteps.ERROR);
		}
		this.isLoading = false;
	}
}