import { observable, action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { IEndpointsConfiguration } from '../../../models/commonTypes';
import { KsysRequest } from '@kurtosys/ksys-api-client/dist/requests/KsysRequest';
import { utils } from '@kurtosys/ksys-app-template';

export class KurtosysApiStoreBase extends StoreBase {
	@observable token: string = '';

	@computed
	get endpointsConfiguration(): IEndpointsConfiguration {
		return (
			(this.storeContext.appStore &&
				this.storeContext.appStore.coreConfigurations &&
				this.storeContext.appStore.coreConfigurations.endpointsConfiguration) ||
			{}
		);
	}

	@computed
	get wrappedToken(): { value: string } {
		return { value: this.token };
	}

	get serviceUrl(): string {
		const { appStore } = this.storeContext;
		if (
			appStore.authentication &&
			appStore.authentication.serviceUrl &&
			appStore.authentication.serviceUrl.length > 0
		) {
			return appStore.authentication.serviceUrl;
		}
		if (
			appStore.configuration &&
			appStore.configuration.core &&
			appStore.configuration.core.serviceUrl
		) {
			return appStore.configuration.core.serviceUrl;
		}
		return this.getBaseAddress();
	}

	@computed
	get options(): IRequestOptions<any> {
		return {
			baseUrl: this.serviceUrl,
		};
	}

	@computed
	get proxyOptions(): IRequestOptions<any> {
		return {
			baseUrl: this.serviceUrl,
			disableCache: true,
			disableCachableRequests: true,
		};
	}

	@action
	async initialize(): Promise<void> { }

	getBaseAddress(suffix: string = 'services/') {
		return this.manifest.getBaseUrl(
			suffix,
			this.storeContext.appStore.appParamsHelper,
		);
	}

	createEndpoint<TRequestOptionsBody = any>(Endpoint: TEndpointClass, options: ICreateEndpointOptions<TRequestOptionsBody> = {}) {
		const { isProxy, optionsOverride } = options;

		let endpointOptions = isProxy ? this.proxyOptions : this.options;
		if (optionsOverride) {
			endpointOptions = utils.object.deepMergeObjects(endpointOptions, optionsOverride);
		}
		return new Endpoint(this.wrappedToken, endpointOptions, this.endpointsConfiguration);
	}

}

type TEndpointClass = new (token: TToken, options: IRequestOptions<any>, endpointsConfiguration?: IEndpointsConfiguration) => KsysRequest<any, any>;

type TToken = { value: string } | undefined;

interface ICreateEndpointOptions<TRequestOptionsBody = any> {
	isProxy?: boolean;
	optionsOverride?: Partial<IRequestOptions<TRequestOptionsBody>>;
}