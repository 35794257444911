// import { Operator } from '@kurtosys/ksys-app-template/dist/models/common';
import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	components: {
		app: {},
		mfaSetup: {
			backButtonIcon: {
				accessibilityText: 'Back to two factor method selection',
				asset: 'base.icons.backArrow',
			},
		},
		setPassword: {
			defaultOptions: {
				heading: 'Set Your Password',
			},
			modes: {
				expiration: {
					query: {
						code: 'queryString',
						key: 'mode',
						queryOptionsType: 'url',
					},
					value: {
						queryOptionsType: 'none',
						value: 'expired',
					},
					// subtitle: {
					// 	queryId: 'cusip',
					// 	queryOptionsType: 'input',
					// 	options: {
					// 		input: {
					// 			key: 'date',
					// 		},
					// 	},
					// },
				},
			},
		},
		userLogin: {
			passwordLabelText: 'Password',
			redirectUrl: {
				options: {
					loggedInUser: {
						code: 'roles',
						key: 'role',
					},
				},
				queryOptionsType: 'loggedInUser',
				transforms: [
					{
						switchOptions: {
							cases: [
								{
									conditional: {
										conditions: [
											{
												operator: 'includes',
												value: 'ClientAdmin',
											},
										],
									},
									id: 'ClientAdmin',
									response: '/client-admin',
								},
								{
									conditional: {
										conditions: [
											{
												operator: 'includes',
												value: 'ApiLoaderUser',
											},
										],
									},
									id: 'ApiLoaderUser',
									response: '/api-loader-user',
								},
								{
									conditional: {
										conditions: [
											{
												operator: 'includes',
												value: 'PortalUser',
											},
										],
									},
									id: 'PortalUser',
									response: '/portal-user',
								},
							],
							defaultResponse: '/',
						},
						transformOptionsType: 'switch',
					},
				],
			},
			usernameLabelText: 'Username',
		},
		feedback: { disableBrowserValidation: true },
	},
	culture: {
		base: 'en-GB',
		default: 'en-GB',
	},
	token: '064d6d3f-8366-4388-ae35-fe6f200fa2d8',
};
