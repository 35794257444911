import { helpers, utils, query, models } from '@kurtosys/ksys-app-template';
import {
	CacheOptions,
	IAppContext,
	IApplicationAssetRegister,
	IComponentStyle,
	IPixelDimensions,
	IQueryContext,
	ISkeletonLoader,
	ISkeletonLoaders,
} from '../../../models/commonTypes';
import { getApplicationCode } from '../../../start';
import { StoreBase } from '../../../common/StoreBase';
import { CONFIGURATION } from '../../../configuration/development.config';
import { AUTHENTICATION } from '../../../configuration/development.authentication';
import { APPLICATION_CLIENT_CONFIGURATION_IDS } from '../../../configuration/development.applicationClientConfigurationIds';
import { APPLICATION_CLIENT_CONFIGURATIONS } from '../../../configuration/development.applicationClientConfigurations';
import { LIBRARY_COMPONENTS_CONFIGURATION } from '../../../configuration/libraryComponentsConfiguration';
import { STYLES } from '../../../configuration/development.styles';
import { IConfiguration } from '../../../models/app/IConfiguration';
import { IInputs } from '../../../models/app/IInputs';
import { IStyles } from '../../../models/app/IStyles';
import { Card as UnstyledCard } from '@kurtosys/ksys-app-components/dist/components/base/Card/Card';
import { IAppComponents } from '../models/IAppComponents';
import { IComponentConfigurations } from '../../../models/app/IComponentConfigurations';
import { IComponentStyles } from '../../../models/app/IComponentStyles';
import { IDebugInfo } from '../../../models/app/IDebugInfo';
import { observable, computed, action, runInAction } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { IGetApplicationAppConfigResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/config/IGetApplicationAppConfigResponseBody';
import { registerAppStart, registerAppLoaded, getAppHydration, getAppKey } from '../../../utils/initialize';
import { IAppConfiguration } from '../models/IAppConfiguration';
import { ICoreConfigurations } from '../../../models/app/ICoreConfigurations';
import { IAssets } from '@kurtosys/ksys-app-components/dist/models/IAssets';
import { Manifest } from '../../../configuration/Manifest';
import { getPreviewContext } from '../../../initialize';
import { ITheme } from '@kurtosys/ksys-app-components/dist/models/ITheme';
import { IGetThemeResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/applicationManager/IGetThemeResponseBody';
import { getStoreKey } from '../../../utils/store';
import { IApplicationEmbedProps } from '@kurtosys/ksys-app-components/dist/components/base/ApplicationEmbed/models/IApplicationEmbedProps';
import { IApplicationEmbedInput } from '@kurtosys/ksys-app-components/dist/components/base/ApplicationEmbed/models/IApplicationEmbedInput';
import { ICardProps } from '@kurtosys/ksys-app-components/dist/components/base/Card/models';
import { IDisclaimerProps } from '@kurtosys/ksys-app-components/dist/components/overview/Disclaimer/models/IDisclaimerProps';
import { getGlobalDisclaimerStore } from '../../../utils/globalDisclaimers';
import { TGetUserByTokenResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TGetUserByTokenResponseBody';
import { isDebug } from '../../../utils/isDebug';
import { getMockData } from '../../../utils/getMockData';
import { debug, error, ILogOptions, info, success, TLogType, warning } from '../../../utils/log';
import { IListenForOptions } from '../../../models/app/IListenForOptions';
import { ResponsiveConfig } from '../../shared/ResponsiveConfig';
import { IBreakpointProps } from '@kurtosys/ksys-app-components/dist/models/IBreakpointProps';
import { Breakpoints } from '../../shared/Breakpoints';
import { Feature } from '../../shared/Feature';
import { IAppInitialisedDetail } from '@kurtosys/ksys-app-template/dist/models/eventBus';

export const DATA_CONTEXT_SEED_KEY = '__ksys-app-data-context-seed__';

export type TDataContextStatus = 'READY' | 'PENDING' | 'UPDATING';
export abstract class AppStoreBase extends StoreBase {
	abstract get hasData(): boolean;

	htmlElement: HTMLElement;
	storeKey: string;
	appParamsHelper: helpers.AppParamsHelper<IInputs, IConfiguration, IStyles>;
	analyticsHelper: helpers.AnalyticsHelper<IAppContext>;
	@observable.ref rawConfiguration: Partial<IConfiguration> = {};
	@observable.ref preventAppLoadedEventFire: boolean = false;
	@observable.ref rawStyles: Partial<IStyles> = {};
	@observable.ref rawThemeResponse: IGetThemeResponseBody | undefined;
	@observable.ref authentication: models.appsInitialize.IAppAuthentication | undefined;
	@observable.ref applicationClientConfigurationIds: number[] | undefined;
	@observable.ref applicationClientConfigurations: models.api.applicationManager.IApplicationClientConfiguration[] = [];
	@observable themeKey: string | undefined;
	@observable.ref public user: TGetUserByTokenResponseBody | undefined;
	// App loading states
	@observable isBootstrapped = false;
	@observable _dataContextStatus: TDataContextStatus = 'PENDING';

	@computed
	get dataContextStatus(): TDataContextStatus {
		return this._dataContextStatus;
	}

	set dataContextStatus(status: TDataContextStatus) {
		if (this._dataContextStatus !== status) {
			runInAction(() => {
				this._dataContextStatus = status;
			});
		}
	}

	constructor(element: HTMLElement, url: string, storeContext: StoreContext, public manifest: Manifest) {
		super(storeContext);
		this.htmlElement = element;
		this.storeKey = getStoreKey(element, url, manifest);
		this.appParamsHelper = new helpers.AppParamsHelper(element, url);
		registerAppStart(manifest, this.appParamsHelper);
		this.themeKey = (this.appParamsHelper.rawAppParams && this.appParamsHelper.rawAppParams.themeKey) || 'default';
		this.analyticsHelper = new helpers.AnalyticsHelper<IAppContext>(this.getDefaultAnalyticsContext);
	}

	getTranslateFunction = () => {
		const { translationStore } = this.storeContext;
		return translationStore.translate;
	}

	getAccessibilityTextFunction = () => {
		const { accessibilityStore } = this.storeContext;
		return accessibilityStore.accessibilityText;
	}

	@computed
	get culture(): string {
		const { translationStore } = this.storeContext;
		return translationStore && translationStore.culture || 'en-GB';
	}

	@computed
	get globalInputIdentifiers(): string[] {
		return (this.appComponentConfiguration && this.appComponentConfiguration.globalInputIdentifiers) || [];
	}

	@computed
	get applicationId(): string {
		return (this.appComponentConfiguration && this.appComponentConfiguration.applicationId) || this.manifest.ksysAppTemplateId;
	}

	@computed
	get listenFor(): IListenForOptions[] {
		return (this.appComponentConfiguration && this.appComponentConfiguration.listenFor) || [];
	}

	/**
	 * Return a URL object based on the current url in the browser
	 *
	 * @readonly
	 */
	get currentUrl(): URL {
		return new URL(document.location.toString());
	}

	get currentUrlKey(): string {
		return btoa(`${ this.currentUrl.host }${ this.currentUrl.pathname }`);
	}

	get currentUrlAndSearchKey(): string {
		return btoa(`${ this.currentUrl.host }${ this.currentUrl.pathname }${ this.currentUrl.search }`);
	}

	// Left blank to allow override
	async customInitializeAfter() { }

	// Left blank to allow override
	async customInitializeBefore() { }

	// left blank to allow override
	async contextsDidUpdateBefore() { }

	async contextsDidUpdate() {
		this.contextsDidUpdateBefore();
		this.setAppCardProps();
		this.contextsDidUpdateAfter();
	}

	// left blank to allow override
	async contextsDidUpdateAfter() { }

	@action setIsBootstrapped() {
		this.isBootstrapped = true;
	}

	@computed
	get isDevelopment(): boolean {
		return this.manifest.isDevelopment;
	}

	@computed
	get applicationGuid(): string | undefined {
		return this.manifest.getApplicationGuid(this.appParamsHelper);
	}

	get isDebug(): boolean {
		return isDebug();
	}

	logDebug(action: string, detail?: any, additionalContext?: string) {
		this.log('debug', {
			detail,
			additionalContext,
			message: action,
		});
	}

	log = (type: TLogType, options: ILogOptions) => {
		let logger;
		const workingOptions = { ...options, configKey: this.configurationKey };
		switch (type) {
			case 'info': logger = info; break;
			case 'success': logger = success; break;
			case 'warning': logger = warning; break;
			case 'error': logger = error; break;
			case 'debug':
			default: logger = debug; break;
		}
		logger(workingOptions);
	}

	logDebugInfo = async (): Promise<void> => {
		const debugInfo = await this.getDebugInfo();
		const debugInfoString = JSON.stringify(debugInfo, null, '\t');
		console.info({ debugInfo });
		console.info('Debug Info copied to clipboard');
		utils.clipboard.copyToClipboard(debugInfoString);

		const filename = `debug_${ getApplicationCode() }_${ new Date().toISOString() }.txt`;
		const element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(debugInfoString));
		element.setAttribute('download', filename);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}

	getDebugInfo = async (): Promise<IDebugInfo> => {
		const user = await this.getUser();
		return {
			user,
			manifest: this.manifest.serialize(),
			inputs: this.appParamsHelper.inputs,
			applicationCode: getApplicationCode(),
			applicationClientConfigurationIds: this.applicationClientConfigurationIds,
			applicationClientConfigurations: this.applicationClientConfigurations,
			authentication: this.authentication,
			configuration: this.rawConfiguration,
			style: this.rawStyles,
			theme: this.rawThemeResponse,
			url: window.location.href,
			serviceUrl: this.serviceUrl,
		};
	}

	getDefaultAnalyticsContext = (): IAppContext => {
		const { rawAppParams = {} } = this.appParamsHelper;
		const { configurationKey = 'default' } = rawAppParams;
		const inputs: any = this.appParamsHelper.inputs;
		return {
			configurationKey,
			appTemplate: this.manifest.ksysAppTemplateId,
			appGuid: this.applicationGuid || 'unknown',
			embedInputs: inputs,
			timestamp: new Date().getTime(),
		};
	}

	@action
	async initialize() {
		const response = await this.getAppConfig();
		if (response) {
			const { kurtosysApiStore } = this.storeContext;
			this.authentication = response.authentication;
			const token = (this.authentication && this.authentication.token) || '';
			kurtosysApiStore.token = token;
			this.applicationClientConfigurationIds = response.applicationClientConfigurationIds;
			this.rawStyles = response.applicationStyles as any as IStyles;
			this.rawConfiguration = await this.buildConfiguration(response.applicationConfiguration as any as IConfiguration);
			const stopInitializeConfiguration = await this.loadPreviewContext();
			if (!stopInitializeConfiguration) {
				await this.initializeConfiguration();
			}
		}
	}

	@action
	async reInitialize() {
		const stopInitializeConfiguration = await this.loadPreviewContext();
		if (!stopInitializeConfiguration) {
			await this.initializeConfiguration();
		}
	}

	async buildConfiguration(configuration: IConfiguration): Promise<IConfiguration> {
		let response: IConfiguration = {};
		const applicationClientConfigurationIds = this.applicationClientConfigurationIds || [];
		if (applicationClientConfigurationIds && applicationClientConfigurationIds.length > 0) {
			if (this.isDevelopment) {
				if (this.applicationClientConfigurations.length === 0) {
					this.applicationClientConfigurations = [...APPLICATION_CLIENT_CONFIGURATIONS];
				}
				const results = applicationClientConfigurationIds.map((id) => {
					const applicationClientConfiguration = this.applicationClientConfigurations.find(a => a.applicationClientConfigurationId === id);
					return applicationClientConfiguration;
				});
				const errors = results.filter(result => !result);
				if (errors.length === 0) {
					response = utils.object.deepMergeObjects(response, ...results.map(result => (result && result.configuration) || {}));
				}
				else {
					this.log('error', {
						configKey: this.configurationKey,
						additionalContext: 'buildConfiguration',
						message: `applicationClientConfigurationIds don't have all available application client configurations in the APPLICATION_CLIENT_CONFIGURATIONS collection`,
						detail: { APPLICATION_CLIENT_CONFIGURATIONS, APPLICATION_CLIENT_CONFIGURATION_IDS },
					});
				}
			}
			else {
				if (this.authentication && this.authentication.token) {
					const promises: Promise<models.api.applicationManager.IApplicationClientConfiguration>[] = [];
					for (const applicationClientConfigurationId of applicationClientConfigurationIds) {
						const existingApplicationClientConfiguration = this.applicationClientConfigurations.find(a => a.applicationClientConfigurationId === applicationClientConfigurationId);
						if (existingApplicationClientConfiguration) {
							promises.push(new Promise((resolve) => {
								resolve(existingApplicationClientConfiguration);
							}));
						}
						const { kurtosysApiStore } = this.storeContext;
						const queryString: any = {
							applicationClientConfigurationId,
						};
						promises.push(
							kurtosysApiStore.getApplicationClientConfiguration.execute({
								queryString,
							}),
						);
					}
					const results = await Promise.all<models.api.applicationManager.IApplicationClientConfiguration>(promises.map(p => p.catch(e => e)));
					const errors = results.filter(result => (result instanceof Error));
					if (errors.length === 0) {
						const resultsToAdd = results.filter((result) => {
							return !this.applicationClientConfigurations.some(a => a.applicationClientConfigurationId === result.applicationClientConfigurationId);
						});
						if (resultsToAdd && resultsToAdd.length > 0) {
							this.applicationClientConfigurations = [
								...this.applicationClientConfigurations,
								...resultsToAdd,
							];
						}
						response = utils.object.deepMergeObjects(response, ...results.map(result => result.configuration));
					}
					else {
						this.log('error', {
							configKey: this.configurationKey,
							additionalContext: 'buildConfiguration',
							message: 'Failed to fetch Client Config',
							detail: errors,
						});
					}
				}
			}
		}
		if (configuration) {
			response = utils.object.deepMergeObjects(response, configuration);
		}
		return response;
	}

	@action
	async loadPreviewContext(): Promise<boolean> {
		let response = false;
		const applicationCode = getApplicationCode();
		const previewContext = getPreviewContext(applicationCode);
		if (previewContext) {
			if (previewContext.themeKey && this.themeKey !== previewContext.themeKey) {
				this.themeKey = previewContext.themeKey;
				await this.loadAppTheme();
			}
			if (previewContext.theme && this.rawThemeResponse) {
				this.rawThemeResponse = {
					...this.rawThemeResponse,
					theme: previewContext.theme,
				};
				this.rawStyles = previewContext.applicationStyles || { ...this.rawStyles };

			}
			if (previewContext.applicationStyles) {
				this.rawStyles = previewContext.applicationStyles;
			}
			if (previewContext.applicationConfiguration) {
				this.rawConfiguration = previewContext.applicationConfiguration;
				this.initializeConfiguration(true);
				response = true;
			}
		}
		return response;
	}

	@action
	initializeConfiguration = async (preventAppLoadedEventFire: boolean = false) => {
		const { kurtosysApiStore, translationStore, queryStore, eventBusStore } = this.storeContext;
		const token = (this.authentication && this.authentication.token) || (this.configuration && this.configuration.token) || '';
		kurtosysApiStore.token = token;
		// Load up the inputs from queries defined in the configuration
		this.initializeInputsFromConfiguration();
		this.loadVersionRegistry();
		await this.customInitializeBefore();
		const promises: Promise<any>[] = [
			this.loadAppTheme(),
			translationStore.loadTranslations(),
			queryStore.initialize(),
		];
		await Promise.all(promises.map(p => p.catch(e => e)));
		this.initializeInputsFromConfiguration();
		await eventBusStore.register();
		await this.debouncePostInitializeConfig(async () => {
			this.setAppCardProps();
			if (this.appCardProps) {
				UnstyledCard.loadSuperscripts(this.appKey, this.storeContext.queryStore, this.appCardProps);
			}
			await this.customInitializeAfter();
			if (!this.preventAppLoadedEventFire && !preventAppLoadedEventFire) {
				this.setIsBootstrapped();
				this.triggerAppInitialized();
			}
		});
	}

	async debouncePostInitializeConfig(callback: () => Promise<void>, time: number = 100) {
		const { eventBusStore } = this.storeContext;
		if (time < 15000) {
			const timeout = setTimeout(async () => {
				if (eventBusStore.requiredCallbacksCompleted && this.dataContextStatus === 'READY') {
					await callback();
				}
				else {
					await this.debouncePostInitializeConfig(callback, time + 100);
				}
			}, 100);
		}
		else {
			this.log('error', {
				message: 'Required events have not initialized the app in under 15 seconds.',
			});
			await callback();
		}
	}

	triggerAppInitialized = (eventDelay = 0) => {
		const { eventBusStore } = this.storeContext;
		const { loadEventDelay = eventDelay } = (this.rawConfiguration && this.rawConfiguration.core) || {};
		setTimeout(() => {
			const eventDetail: CustomEventInit<IAppInitialisedDetail> = {
				detail: {
					hasData: this.hasData,
				},
			};
			eventBusStore.trigger(models.eventBus.EventType.appInitialized, eventDetail);
			this.logDebug(models.eventBus.EventType.appInitialized, eventDetail);
			registerAppLoaded(this.manifest, this.appParamsHelper);
		}, loadEventDelay);
	}

	@action setPreventAppLoadedEventFire(shouldPrevent: boolean) {
		this.preventAppLoadedEventFire = shouldPrevent;
	}

	@action loadVersionRegistry = () => {
		const key = '__ksysAppVersionRegistry__';
		if (!(window as any)[key]) {
			(window as any)[key] = [];
		}
		const registry = (window as any)[key];
		const applicationCode = getApplicationCode();
		const templateId = this.manifest.ksysAppTemplateId;
		const version = this.manifest.version;
		registry.push({
			applicationCode,
			templateId,
			version,
		});
	}

	@action
	initializeInputsFromConfiguration = () => {
		const { inputs } = this.coreConfigurations;
		if (inputs) {
			const { key, queries, sourceKeys } = inputs;
			const globalInputs = this.globalInputs;
			if (sourceKeys && sourceKeys.length > 0) {
				sourceKeys.forEach((sourceKey) => {
					const sourceInputs = globalInputs[sourceKey];
					if (sourceInputs) {
						this.appParamsHelper.loadInputsFromConfiguration(sourceInputs);
					}
				});
			}
			if (queries) {
				const values: { [key: string]: any; } = {};
				queries.forEach((queryOptions) => {
					const { queryId = 'NO QUERY ID' } = queryOptions;
					const executionOptions = {
						inputs: this.appParamsHelper.inputs,
					};
					const { queryStore } = this.storeContext;
					const value = queryStore.query(queryOptions, executionOptions);
					this.appParamsHelper.loadInputsFromConfiguration({ [queryId]: value });
					values[queryId] = value;
				});
				if (key) {
					this.globalInputs[key] = values;
				}
			}
		}
	}

	inputsWindowKey = '__ksysDynamicInputs__';
	get globalInputs(): { [key: string]: object; } {
		if (!(window as any)[this.inputsWindowKey]) {
			(window as any)[this.inputsWindowKey] = {};
		}
		return (window as any)[this.inputsWindowKey];
	}

	set globalInputs(value: { [key: string]: object; }) {
		(window as any)[this.inputsWindowKey] = value;
	}

	@computed
	get skeletonLoaders(): ISkeletonLoaders | undefined {
		return this.coreConfigurations && this.coreConfigurations.skeletonLoaders;
	}

	@computed
	get skeletonLoader(): ISkeletonLoader | undefined {
		const skeletonLoaders = this.skeletonLoaders;
		if (skeletonLoaders) {
			const { loaders = [] } = skeletonLoaders;
			return loaders.find((loader) => {
				const { id, configurationKey: loaderConfigurationKey = 'default', styleKey: loaderStyleKey = 'default' } = loader;
				const { configurationKey = 'default', styleKey = 'default' } = this.appParamsHelper.rawAppParams || {};
				return id === this.manifest.ksysAppTemplateId && configurationKey === loaderConfigurationKey && styleKey === loaderStyleKey;
			});
		}
	}

	@computed
	get rawTheme(): ITheme {
		return (this.rawThemeResponse && this.rawThemeResponse.theme) || {};
	}

	@computed
	get show(): boolean {
		if (this.appComponentConfiguration && this.appComponentConfiguration.noDataOptions) {
			const { hide = true } = this.appComponentConfiguration.noDataOptions;
			if (!hide) {
				return true;
			}
		}
		return this.customHasDataValidation(this.hasData);
	}

	@computed
	get showPlaceholder(): boolean {
		return (this.show && !this.customHasDataValidation(this.hasData) && (this.noDataPlaceholder !== undefined || this.noDataPlaceholderDisclaimer !== undefined));
	}

	@computed
	get serviceUrl(): string | undefined {
		if (this.storeContext) {
			const { kurtosysApiStore } = this.storeContext;
			if (kurtosysApiStore) {
				return kurtosysApiStore.serviceUrl;
			}
		}
		return;
	}

	customHasDataValidation(hasData: boolean): boolean {
		if (this.appComponentConfiguration) {
			const { noDataOptions } = this.appComponentConfiguration;
			if (noDataOptions) {
				const { customValidation } = noDataOptions;
				if (customValidation) {
					const { queryStore } = this.storeContext;
					const { includeDefaultValidation, validateWithDefaultAs, conditionalValidation } = customValidation;
					const conditionalHelper = new helpers.ConditionalHelper(conditionalValidation);
					const isValid = conditionalHelper.matchesWithOptions({ executionOptions: queryStore.executionOptions });
					if (includeDefaultValidation) {
						switch (validateWithDefaultAs) {
							case 'ANY':
								hasData = hasData || isValid;
								break;
							case 'BOTH':
							default:
								hasData = hasData && isValid;
								break;
						}
					}
					else {

						hasData = isValid;
					}
				}
			}
		}
		return hasData;
	}

	@computed
	get noDataPlaceholder(): string | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.noDataOptions && this.appComponentConfiguration.noDataOptions.noDataPlaceholder;
	}

	@computed
	get noDataPlaceholderDisclaimer(): IDisclaimerProps | undefined {
		const placeHolderText = this.appComponentConfiguration && this.appComponentConfiguration.noDataOptions && this.appComponentConfiguration.noDataOptions.noDataPlaceholderDisclaimer;
		return this.mergeQueriesAndProps(placeHolderText);
	}

	@computed
	get dataContextSeed(): IQueryContext | undefined {
		const contextSeedConfiguration = this.coreConfigurations && this.coreConfigurations.contextSeed;
		if (contextSeedConfiguration) {
			const contextAndConfiguration = query.Query.contextStore.get(contextSeedConfiguration);
			if (contextAndConfiguration && contextAndConfiguration.context) {
				return contextAndConfiguration.context;
			}
		}
		const applicationCode = getApplicationCode();
		const keyWithApplicationCode = `${ DATA_CONTEXT_SEED_KEY }${ applicationCode || '' }`;
		if ((window as any)[keyWithApplicationCode]) {
			return (window as any)[keyWithApplicationCode];
		}
		return undefined;
	}

	@action
	async getAppConfig(): Promise<IGetApplicationAppConfigResponseBody> {
		const appFromHydration = getAppHydration(this.manifest, this.appParamsHelper);
		if (appFromHydration) {
			const { applicationConfiguration, applicationStyles, authentication, applicationClientConfigurationIds } = appFromHydration;
			return {
				applicationConfiguration,
				applicationStyles,
				authentication,
				applicationClientConfigurationIds,
			};
		}
		// We want to use the hard coded configuration when developing locally
		// otherwise fetch it
		let response: IGetApplicationAppConfigResponseBody = {
			applicationConfiguration: CONFIGURATION,
			applicationStyles: STYLES,
			authentication: AUTHENTICATION,
			applicationClientConfigurationIds: APPLICATION_CLIENT_CONFIGURATION_IDS,
		};

		// Lazy Load Development Mock Data
		const MockData = await getMockData();
		if (MockData) {
			const mockAuth = MockData.getWorkingAuthentication() || {};
			const mockEndpoints = MockData.getWorkingEndpointConfiguration();
			const mockDataContext = MockData.getWorkingDataContext();
			const mockConfig = MockData.getWorkingConfiguration();
			response.authentication = { ...response.authentication, ...mockAuth };
			const mockConfiguration: Record<string, any> = {};
			if (mockConfig) {
				mockConfiguration.components = mockConfig;
			}
			if (mockDataContext) {
				if (!mockConfiguration.data) {
					mockConfiguration.data = {};
				}
				mockConfiguration.data.context = mockDataContext;
			}
			if (mockEndpoints) {
				if (!mockConfiguration.core) {
					mockConfiguration.core = {};
				}
				if (!mockConfiguration.core.endpointsConfiguration) {
					mockConfiguration.core.endpointsConfiguration = {};
				}
				mockConfiguration.core.endpointsConfiguration.endpoints = mockEndpoints;
			}
			response.applicationConfiguration = utils.object.deepMergeObjectsWithOptions(
				{ arrayMergeStrategy: 'DeepCopy' },
				response.applicationConfiguration,
				mockConfiguration,
			);
		}

		if (!this.isDevelopment) {
			response = await this.getAppConfigFromApi();
		}
		return response;
	}

	@computed
	get configurationKey() {
		const { rawAppParams = {} } = this.appParamsHelper;
		const { configurationKey = 'default' } = rawAppParams;
		return configurationKey;
	}

	@computed
	get styleKey() {
		const { rawAppParams = {} } = this.appParamsHelper;
		const { styleKey } = rawAppParams;
		return styleKey;
	}

	@computed
	get appKey() {
		const value = getAppKey(this.manifest, this.appParamsHelper);
		return value;
	}

	@action
	async getAppConfigFromApi(): Promise<IGetApplicationAppConfigResponseBody> {
		const urlParams: string[] = [this.configurationKey];
		// Only add the styleKey if it is defined and not default to help with backwards compatibility, until services are deployed.
		if (this.styleKey && this.styleKey !== 'default') {
			urlParams.push(this.styleKey);
		}
		const { kurtosysApiStore } = this.storeContext;
		return await kurtosysApiStore.getAppConfig.execute({
			urlParams,
		});
	}

	@action
	async loadAppTheme(): Promise<void> {
		const themeKey = this.themeKey;
		if (!this.rawThemeResponse || this.rawThemeResponse.name !== themeKey) {
			const { kurtosysApiStore } = this.storeContext;
			try {
				const queryString: any = {
					type: 'apps',
					name: themeKey,
				};
				const themeResponse = await kurtosysApiStore.getTheme.execute({
					queryString,
				});
				if (themeResponse) {
					this.rawThemeResponse = themeResponse;
				}
			}
			catch (ex) {
				this.log('warning', {
					configKey: this.configurationKey,
					message: `Failed to load theme "${ themeKey }"`,
					detail: ex,
				});
			}
		}
	}

	@computed
	get assetsBaseUrl(): string {
		const { kurtosysApiStore } = this.storeContext;
		return kurtosysApiStore.getBaseAddress('');
	}

	@computed
	get assetCacheOptions(): CacheOptions | undefined {
		return (this.coreConfigurations && this.coreConfigurations.assetCacheOptions) || undefined;
	}

	// Leaving this as uncomputed on purpose
	// Hydration is not using an observable
	get assetRegisters(): IApplicationAssetRegister[] | undefined {
		const appHydration = getAppHydration(this.manifest, this.appParamsHelper);
		if (appHydration && appHydration.app && appHydration.app.assetRegister) {
			return appHydration.app.assetRegister;
		}
	}

	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	@computed
	get appComponentConfiguration(): IAppConfiguration | undefined {
		const appConfig = this.getComponentConfiguration('app');
		if (appConfig) {
			return appConfig;
		}
		return;
	}

	@computed
	get assets(): IAssets | undefined {
		const assets = this.configuration && this.configuration.assetOverrides;
		if (assets && utils.typeChecks.isNullOrEmpty(assets.baseUrl)) {
			assets.baseUrl = this.assetsBaseUrl;
		}
		return assets;
	}

	@computed
	get configuration(): IConfiguration | undefined {
		if (this.rawConfiguration) {
			return this.rawConfiguration as IConfiguration;
		}
	}
	@computed
	get styles(): IStyles | undefined {
		if (this.rawStyles) {
			if (this.rawTheme) {
				const { theme: stylesTheme = {}, ...styles } = this.rawStyles;
				const dirty = (obj: any) => JSON.parse(JSON.stringify(obj));
				const theme = utils.object.deepMergeObjectsWithOptions({ arrayMergeStrategy: 'AppendDeepCopy' }, this.rawTheme, dirty(stylesTheme));
				return { theme, ...styles } as IStyles;
			}
			return this.rawStyles as IStyles;
		}
	}
	@computed
	get libraryComponentsConfiguration(): any | undefined {
		return utils.object.deepMergeObjects(LIBRARY_COMPONENTS_CONFIGURATION, (this.configuration && this.configuration.libraryComponents) || {});
	}
	@computed
	get coreConfigurations(): ICoreConfigurations {
		let response: ICoreConfigurations | undefined;
		if (this.configuration && this.configuration.core) {
			response = this.configuration.core;
		}
		if (!response) {
			response = {};
		}
		return response;
	}
	@computed
	get theme(): any | undefined {
		return this.styles && this.styles.theme;
	}

	@computed
	get responsiveConfiguration(): ResponsiveConfig<IComponentConfigurations> | undefined {
		if (Feature.useResponsiveComponentConfiguration && this.configuration) {
			const { components } = this.configuration;
			const breakpoints: IBreakpointProps<IComponentConfigurations>[] = (this.configuration as any).breakpoints || [];
			return new ResponsiveConfig(this.storeContext, components, breakpoints, 'ApplicationConfig');
		}
		return;
	}

	@computed
	get componentConfiguration(): IComponentConfigurations {
		if (Feature.useResponsiveComponentConfiguration) {
			if (this.responsiveConfiguration) {
				this.log('debug', {
					additionalContext: Breakpoints.active,
					message: 'Active Responsive Config',
					detail: this.responsiveConfiguration.activeConfiguration,
				});
				return this.responsiveConfiguration.activeConfiguration;
			}
		}
		else if (this.configuration && this.configuration.components) {
			return this.configuration.components;
		}
		return {};
	}

	@computed
	get componentStyles(): IComponentStyles {
		if (this.rawStyles && this.styles && this.styles.components) {
			return this.styles.components;
		}
		return {};
	}

	@computed
	get isSnapshot(): boolean {
		const value = utils.url.getQueryStringValue({ key: 'isSnapshot' }, window.location.search);
		return value === 'true';
	}

	getInput<T extends keyof IInputs>(inputKey: T): IInputs[T] | undefined {
		return this.appParamsHelper.inputs && this.appParamsHelper.inputs[inputKey];
	}
	getComponentConfiguration<K extends keyof IComponentConfigurations>(componentKey: K) {
		return this.componentConfiguration[componentKey];
	}
	getComponentStyle<K extends keyof IComponentStyles>(componentKey: K, ...childKeys: string[]): IComponentStyle | undefined {
		let response: IComponentStyle | undefined = this.componentStyles[componentKey] as IComponentStyle;
		if (childKeys) {
			for (const childKey of childKeys) {
				if (response && response.children && (response.children as any)[childKey]) {
					response = (response.children as any)[childKey];
				}
				else {
					response = undefined;
					break;
				}
			}
		}
		return response;
	}
	getDimensions = (): IPixelDimensions => {
		return utils.layout.getHtmlElementSize(this.htmlElement);
	}
	getCoreEmbed(embedId: string): IApplicationEmbedProps | undefined {
		if (this.configuration && this.configuration.core && this.configuration.core.embedding && this.configuration.core.embedding.embeds) {
			const embed = this.configuration.core.embedding.embeds.find(embed => embed.embedId === embedId);
			const hostname = this.configuration.core.embedding.hostname;
			if (embed) {
				const response: IApplicationEmbedProps = { ...embed };
				if (hostname && !response.hostname) {
					response.hostname = hostname;
				}
				return response;
			}
		}
	}
	orchestrateEmbed(rawEmbed: Partial<IApplicationEmbedProps>, defaultTemplateId: string, inputs?: IApplicationEmbedInput[], onLoad?: (element: HTMLElement) => void): IApplicationEmbedProps {
		const { appStore } = this.storeContext;
		const embedId = rawEmbed && rawEmbed.embedId;
		const coreEmbed: Partial<IApplicationEmbedProps> = (embedId && appStore.getCoreEmbed(embedId)) || {};
		const props: IApplicationEmbedProps = utils.object.deepMergeObjects(
			{
				templateId: defaultTemplateId,
				hostname: this.manifest.getBaseUrl(''),
			},
			coreEmbed,
			rawEmbed,
		);
		props.inputs = [
			...(props.inputs || []),
			...(inputs || []),
		];
		if (onLoad) {
			props.onLoad = onLoad;
		}
		return props;
	}

	@computed
	get globalFormatsByKey(): models.query.IGlobalFormatsByKey {
		const globalQueryFormats = (this.coreConfigurations && this.coreConfigurations.globalQueryFormats) || [];
		return utils.collection.pivotCollection(globalQueryFormats, item => item.key);
	}

	@observable.ref
	appCardProps: ICardProps | undefined;

	@action
	setAppCardProps(): void {
		// in some projects we don't have cardProps within the app configuration (hence the 'anyæ to prevent type breaks)
		const cardPropsRaw = this.appComponentConfiguration && (this.appComponentConfiguration as any).cardProps;
		if (cardPropsRaw) {
			const cardProps: ICardProps | undefined = this.mergeQueriesAndProps(cardPropsRaw) || {};
			cardProps.titleId = cardProps.titleId || `${ getAppKey(this.manifest, this.appParamsHelper) }`;

			if (cardProps && !cardProps.footerDisclaimers) {
				const globalDisclaimerStore = getGlobalDisclaimerStore();
				if (globalDisclaimerStore) {
					const disclaimerOptions = this.coreConfigurations.disclaimerOptions;
					const level = (disclaimerOptions && disclaimerOptions.level) || models.disclaimers.GlobalDisclaimerLevel.app;
					const disclaimers = globalDisclaimerStore.get(level, this.appKey);
					if (disclaimers && disclaimers.length > 0) {
						const separator = (disclaimerOptions && disclaimerOptions.separator) || ' ';
						cardProps.footerDisclaimers = {
							separator,
							disclaimers: disclaimers.map((disclaimer) => {
								return {
									text: disclaimer.text,
									hasHtmlContent: disclaimer.hasHtml,
									superscript: disclaimer.superscript.toString(),
								};
							}),
						};

					}
				}
			}
			this.logDebug('App Store Base > Set App Card Props', cardProps);
			this.appCardProps = cardProps;
		}
	}

	@action
	public getUser = async () => {
		if (!this.user) {
			const { kurtosysApiStore } = this.storeContext;
			this.user = await kurtosysApiStore.getUserByToken.execute();
			await this.onGetUser();
		}
		return this.user;
	}

	@action
	public onGetUser = async () => {
		// Setup to override
	}
}
