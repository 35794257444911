import { IStyles } from '../models/app/IStyles';

export const STYLES: IStyles = {
	components: {
		app: {
			children: {
				buttonRaised: {
					cursor: 'pointer',
					letterSpacing: '0',
					lineHeight: '1',
					margin: {
						bottom: '20px',
						top: '20px',
					},
				},
				buttonText: {
					background: {
						color: 'transparent',
					},
					color: '#A2A3A4',
					font: {
						size: '16px',
					},
					height: '100%',
					letterSpacing: '0',
					lineHeight: '24px',
					padding: {
						_value: '0',
					},
				},
				logoWrapper: {
					display: 'none',
				},
				wrapper: {
					align: {
						items: 'center',
					},
					display: 'flex',
					flex: {
						direction: 'column',
					},
					height: '100%',
					justifyContent: 'center',
					padding: {
						_value: '0;form > div:nth-child(8) { position: relative; } form > div:nth-child(8):after { content: ""; display: block; width: 100%; height: 5px; clear: both; } form > label:nth-child(9) { float: left; width: 50%; max-width: 260px; } form > div:nth-child(10) { float: left; width: 50%; max-width: 260px; clear: left; } form > label:nth-child(11) { display: block; width: 50%; max-width: 260px; float: right; margin-top: -20px; } form > div:nth-child(12) { float: right; width: 50%; max-width: 260px; } form > label:nth-child(13) { clear: both; float: left; margin-top: 5px; margin-bottom: 10px; } form > div:nth-child(14) { clear: both; }',
					},
					position: 'relative',
					width: '100%',
				},
			},
		},
		forgotPassword: {
			breakpoints: [
				{
					max: '500px',
					style: {
						width: '100%',
					},
				},
			],
			children: {
				label: {
					color: '#002949',
					display: 'block',
					font: {
						size: '16px',
					},
					margin: {
						bottom: '5px',
					},
				},
				wrapper: {
					overrides: [
						{
							selector: 'input',
							style: {
								breakpoints: [
									{
										max: '500px',
										style: {
											width: '100%',
										},
									},
								],
								width: '540px',
							},
						},
						{
							selector: 'button',
							style: {
								selectors: [
									{
										':first-child': {
											display: 'none',
										},
									},
								],
							},
						},
					],
				},
			},
			overrides: [
				{
					selector: 'button',
					style: {
						margin: {
							top: '10px',
						},
					},
				},
			],
		},
		passwordValidation: {
			children: {
				rule: {
					color: '#C6C6C6',
					display: 'flex',
					font: {
						size: '16px',
					},
				},
				ruleIcon: {
					margin: {
						right: '0.5em',
					},
					transform: 'transform: scale(0.7)',
				},
				title: {
					color: '#FFFFFF',
					margin: {
						_value: '0 0.25em 0.5em',
					},
				},
				wrapper: {
					background: {
						color: '#424242',
					},
					border: {
						radius: '0px',
					},
					breakpoints: [
						{
							min: '900px',
							style: {
								background: {
									color: '#002949',
								},
								left: 'calc(100% - 200px)',
								margin: {
									top: '40px',
								},
								min: {
									width: '400px',
								},
								position: 'absolute',
								top: '0',
							},
						},
					],
					padding: {
						_value: '1em 1.5em',
					},
					text: {
						align: 'left',
					},
				},
			},
		},
		setPassword: {
			children: {
				heading: {
					font: {
						family: '"FranklinGothicURW-Med", Sans-serif',
						size: '30px',
					},
					letterSpacing: '-0.15px',
					lineHeight: '1.2',
					margin: {
						bottom: '25px',
					},
				},
				label: {
					font: {
						size: '16px',
					},
					lineHeight: '24px',
				},
				wrapper: {
					padding: {
						_value: '0',
					},
				},
			},
		},
		userLogin: {
			children: {
				wrapper: {
					align: {
						items: 'flex-start',
					},
					background: {
						color: '#FFFFFF',
					},
					justifyContent: 'flex-start',
				},
			},
		},
		registration: {
			overrides: [
				{
					selector: 'label[readOnly]',
					style: {
						color: '#bababa',
					},
				},
				{
					selector: 'input[readOnly]',
					style: {
						color: '#bababa',
						background: {
							color: '#efefef',
						},
						cursor: 'default',
					},
				},
			],
		},
	},
	theme: {
		base: {
			application: {
				min: {
					height: '100%',
				},
			},
			button: {
				font: {
					size: '16px',
				},
			},
			buttonFlat: {
				align: {
					items: 'center',
				},
				display: 'flex',
			},
			card: {
				breakpoints: [
					{
						max: '768px',
						media: ['screen'],
						style: {
							width: 'unset',
						},
					},
				],
				children: {
					footer: {
						font: {
							size: '16px',
						},
						lineHeight: '1.6',
						margin: {
							_value: '20px 0 0 0',
						},
					},
					title: {
						font: {
							size: '30px',
							weight: 'normal',
						},
						lineHeight: '36px',
					},
				},
				width: '540px',
			},
			dropdown: {
				children: {
					header: {
						border: {
							_value: '1px solid #C2C9D4',
						},
						color: '#0F364E',
						cursor: 'pointer',
						display: 'flex',
						font: {
							family: '"franklingothicurw-boo", sans-serif',
						},
						justifyContent: 'space-between',
						margin: {
							_value: '15px 0 0 0',
						},
						max: {
							height: '45px',
						},
						padding: {
							_value: '8px 0px 12px 12px',
						},
					},
					headerIcon: {
						height: '24px',
						position: 'relative',
						right: '10px',
						top: '3px',
						width: '24px',
					},
					headerTitle: {
						font: {
							size: '16px',
						},
					},
					label: {
						color: '#0F364E',
						font: {
							family: '"franklingothicurw-boo", sans-serif;',
							size: '20px',
							weight: 'normal',
						},
						lineHeight: 'normal',
						padding: {
							_value: '0px 0px 7px 0px',
						},
					},
					list: {
						_raw: 'ul { position: relative; z-index: 200 }',
						background: {
							_value: '#fff',
						},
						border: {
							_value: '1px solid rgb(194, 201, 212)',
						},
						margin: {
							_value: '-1px 0 0 0',
						},
						padding: {
							_value: '0',
						},
						position: 'absolute',
						top: '100%',
						width: '100%',
						zIndex: '200',
					},
					listItem: {
						background: {
							_value: '#fff',
						},
						cursor: 'pointer',
						font: {
							size: '18px',
						},
						padding: {
							_value: '10px 20px',
						},
						selectors: {
							':hover': {
								background: {
									color: '#f3fafd',
								},
								color: '#002949',
							},
						},
					},
					listItems: {
						listStyle: {
							_value: 'none',
						},
						margin: {
							_value: '0',
						},
						padding: {
							_value: '0',
						},
					},
				},
			},
			icon: {
				children: {
					wrapper: {
						display: 'flex',
						padding: {
							_value: '0',
						},
					},
				},
			},
			iconInput: {
				border: {
					_value: 'none',
					radius: '0',
				},
				children: {
					errorWrapper: {
						overrides: [
							{
								selector: 'input',
								style: {
									border: {
										color: '#ff7449',
										radius: '1px',
										style: 'solid',
										width: '2px',
									},
									selectors: {
										':focus': {
											border: {
												color: '#ff7449',
												style: 'solid',
												width: '2px',
											},
										},
									},
								},
							},
						],
					},
					iconWrapper: {
						display: 'none',
						left: '0.75em',
						overrides: [
							{
								selector: 'input',
								style: {
									margin: {
										top: '27px',
									},
								},
							},
						],
						position: 'absolute',
						top: '50%',
						transform: 'translateY(-50%)',
					},
					inputComponent: {
						box: {
							sizing: 'border-box',
						},
						color: '#0F364E',
						font: {
							family: '"franklingothicurw-boo", sans-serif',
							size: '16px',
						},
						height: '45px',
						outline: {
							style: 'none',
							width: '0',
						},
						padding: {
							_value: '0 1em',
						},
						selectors: [
							{
								'::placeholder': {
									color: '#ffffff',
								},
							},
						],
						width: '100%',
					},
					standardWrapper: {
						overrides: [
							{
								selector: 'input',
								style: {
									border: {
										color: '#C2C9D4',
										radius: '1px',
										style: 'solid',
										width: '1px',
									},
									selectors: {
										':focus': {
											border: {
												color: '#1a80ff',
												style: 'solid',
												width: '2px',
											},
										},
									},
								},
							},
						],
					},
					wrapper: {
						margin: {
							_value: '10px 0',
						},
						max: {
							width: '100%',
						},
						position: 'relative',
					},
				},
				overrides: [
					{
						selector: 'img',
						style: {
							display: 'none',
						},
					},
				],
			},
			label: {
				color: '#0F364E',
				font: {
					family: '"franklingothicurw-boo", sans-serif;',
					size: '20px',
					weight: 'normal',
				},
				lineHeight: '20px',
				padding: {
					_value: '0',
				},
			},
		},
		message: {
			base: {
				background: {
					_value: '#fff',
				},
				border: {
					_value: '1px solid transparent',
				},
				color: '#002949',
				font: {
					size: '16px',
				},
				margin: {
					_value: '20px 0 20px 0',
				},
				padding: {
					_value: '16px 0 14px 14px',
				},
				selectors: {
					':empty': {
						display: 'none',
					},
				},
			},
			errorMessage: {
				background: {
					_value: '#FFEBED',
				},
				border: {
					_value: '1px solid #FAC7CC',
				},
				color: '#002949',
				margin: {
					_value: '20px 0 0 0',
				},
				padding: {
					_value: '16px 0 14px 14px',
				},
				selectors: {
					':empty': {
						display: 'none',
					},
				},
			},
			infoMessage: {
				background: {
					_value: '#D6EDFE',
				},
				border: {
					_value: '1px solid #0099CC',
				},
				color: '#002949',
				margin: {
					_value: '20px 0 0 0',
				},
				padding: {
					_value: '16px 0 14px 14px',
				},
				selectors: {
					':empty': {
						display: 'none',
					},
				},
			},
			successMessage: {
				background: {
					_value: '#D6EDFE',
				},
				border: {
					_value: '1px solid #0099CC',
				},
				color: '#002949',
				margin: {
					_value: '20px 0 0 0',
				},
				padding: {
					_value: '16px 0 14px 14px',
				},
				selectors: {
					':empty': {
						display: 'none',
					},
				},
			},
		},
	},
};
