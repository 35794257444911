import React, { Component, Fragment } from 'react';
import { IMfaSetupBackupProps, IMfaSetupBackupState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Heading from '@kurtosys/ksys-app-components/dist/components/base/Heading';
import Wrapper from './styledComponents/Wrapper';
import PrefixTextWrapper from './styledComponents/PrefixTextWrapper';
import SuffixTextWrapper from './styledComponents/SuffixTextWrapper';
import Buttons from './styledComponents/Buttons';
import LoadingWrapper from './styledComponents/LoadingWrapper';
import InfoMessage from '../App/styledComponents/InfoMessage';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import LoadingIndicator from '@kurtosys/ksys-app-components/dist/components/base/LoadingIndicator';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import IconInput from '../App/styledComponents/IconInput';
import PhoneNumber from '../PhoneNumber';
import Label from './styledComponents/Label';

@inject('appStore', 'mfaSetupBackupStore', 'messageStore', 'loginCodeStore')
@observer
export class MfaSetupBackup extends Component<IMfaSetupBackupProps, IMfaSetupBackupState> {
	static configurationKey: 'mfaSetupBackup' = 'mfaSetupBackup';
	static styleKey: 'mfaSetupBackup' = 'mfaSetupBackup';
	constructor(props: IMfaSetupBackupProps) {
		super(props);
	}
	render() {
		const { className, mfaSetupBackupStore, messageStore, loginCodeStore, appStore } =
			this.props;

		if (!mfaSetupBackupStore || !messageStore || !loginCodeStore) {
			return null;
		}
		const {
			headingProps,
			prefixText,
			suffixText,
			showSkip,
			skipDescriptionText,
			skipLinkText,
			skipAction,
			loadingAuth,
			callEnabled,
			textEnabled,
			callButtonText,
			textButtonText,
			showCode,
			confirmTextCode,
			confirmationCode,
			setConfirmationCode,
			canProceed,
			phoneNumberProps,
			callButtonProps,
			textButtonProps,
		} = mfaSetupBackupStore;
		return (
			<Wrapper className={className}>
				<Heading {...headingProps} />

				<PrefixTextWrapper>
					<InfoMessage>{prefixText}</InfoMessage>
				</PrefixTextWrapper>

				{showSkip && (
					<InfoMessage>
						<a onClick={skipAction}>{skipLinkText}</a> {skipDescriptionText}
					</InfoMessage>
				)}
				<form
					noValidate={appStore && appStore.disableBrowserValidation}
					spellCheck={false}
					autoSave="false"
					onSubmit={(e) => {
						e.preventDefault();
						return false;
					}}
				>
					<PhoneNumber {...phoneNumberProps} />
					<SuffixTextWrapper>
						<InfoMessage>{suffixText}</InfoMessage>
					</SuffixTextWrapper>
					<ErrorMessage>{messageStore.errorText}</ErrorMessage>
					{loadingAuth && (
						<LoadingWrapper>
							<LoadingIndicator />
						</LoadingWrapper>
					)}
					{!loadingAuth && (
						<Fragment>
							{!canProceed && (
								<Buttons>
									{callEnabled && (
										<ButtonRaised {...callButtonProps}>
											{callButtonText}
										</ButtonRaised>
									)}
									{textEnabled && (
										<ButtonRaised {...textButtonProps}>
											{textButtonText}
										</ButtonRaised>
									)}
								</Buttons>
							)}
							{showCode && (
								<Fragment>
									{loginCodeStore.labelText && (
										<Label
											htmlFor="loginCodeInput"
											value={loginCodeStore.labelText}
										/>
									)}
									<IconInput
										id="loginCodeInput"
										name="loginCode"
										aria-label={
											!loginCodeStore.labelText &&
											loginCodeStore.placeholderText
										}
										placeholder={loginCodeStore.placeholderText}
										type="text"
										autoFocus
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											setConfirmationCode(event.target.value)
										}
										value={confirmationCode}
										iconProps={{ asset: 'base.iconInput.shield' }}
										hasError={messageStore.hasErrorText}
										pattern="[0-9]*"
										onKeyDown={() => {
											confirmTextCode();
										}}
										data-qa-ref="loginCodeInput"
										required
										aria-required="true"
										autoComplete="one-time-code"
									/>
									<ButtonRaised onClick={confirmTextCode} width="55%">
										{loginCodeStore.confirmButtonText}
									</ButtonRaised>
								</Fragment>
							)}
						</Fragment>
					)}
				</form>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(MfaSetupBackup, 'mfaSetupBackup');
