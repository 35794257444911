import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IUserLoginConfiguration } from '../models';
import { utils, helpers } from '@kurtosys/ksys-app-template';
import { config } from '../../App/models/config';
import { SetupAssuranceSource } from '../../SetupAssurances/models';
import { IRegistrationConfiguration } from '../../Registration/models/IRegistrationConfiguration';
import { RegistrationStore } from '../../Registration/stores/RegistrationStore';
import { IButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/Button/models/IButtonProps';
import { IRegistrationButtonOptions } from '../models/IRegistrationButtonOptions';

const { isNullOrEmpty } = utils.typeChecks;

export class UserLoginStore extends StoreBase {
	static componentKey: 'userLogin' = 'userLogin';
	@observable public token: string | undefined;
	@observable public username: string = '';
	@observable public password: string = '';

	@computed
	get configuration(): IUserLoginConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(
				UserLoginStore.componentKey
			);
		}
	}

	@action
	async initialize(): Promise<void> {}

	@computed
	get backButtonText(): string {
		return (
			(this.configuration && this.configuration.backButtonText) ||
			'Select Strategy'
		);
	}

	@computed
	get loginButtonText(): string {
		return (
			(this.configuration && this.configuration.loginButtonText) || 'Login'
		);
	}

	@computed
	get registrationButtonLabel(): string {
		return (
			(this.registrationConfiguration &&
				this.registrationConfiguration.textProps) ||
			'don’t have access?'
		);
	}

	@computed
	get registrationConfiguration(): IRegistrationConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(
				RegistrationStore.componentKey
			);
		}
	}

	@computed
	get showRegistrationButton(): boolean {
		if (
			this.registrationButtonOptions &&
			this.registrationButtonOptions.button
		) {
			return (
				this.registrationButtonOptions.type === 'button' ||
				this.registrationButtonOptions.type === 'both'
			);
		}
		return false;
	}

	@computed
	get showRegistrationLink(): boolean {
		if (
			this.registrationButtonOptions &&
			this.registrationButtonOptions.linkText
		) {
			return (
				this.registrationButtonOptions.type === 'link' ||
				this.registrationButtonOptions.type === 'both'
			);
		}
		return false;
	}
	@computed
	get registrationLinkText(): string | undefined {
		return (
			this.registrationButtonOptions && this.registrationButtonOptions.linkText
		);
	}

	@computed
	get registrationButtonText(): string | undefined {
		return (
			this.configuration &&
			this.registrationButtonOptions &&
			this.registrationButtonOptions.button &&
			this.registrationButtonOptions.button.text
		);
	}

	@computed
	get registrationButtonOptions(): IRegistrationButtonOptions | undefined {
		return this.configuration && this.configuration.registrationButtonOptions;
	}

	@computed
	get usernamePlaceholderText(): string {
		return (
			(this.configuration && this.configuration.usernamePlaceholderText) ||
			'Username'
		);
	}

	@computed
	get usernameLabelText(): string | undefined {
		return this.configuration && this.configuration.usernameLabelText;
	}

	@computed
	get passwordPlaceholderText(): string {
		return (
			(this.configuration && this.configuration.passwordPlaceholderText) ||
			'Password'
		);
	}

	@computed
	get passwordLabelText(): string | undefined {
		return this.configuration && this.configuration.passwordLabelText;
	}

	@computed
	get forgotUsernameButtonText(): string {
		return (
			(this.configuration && this.configuration.forgotUsernameButtonText) ||
			'Forgot Username?'
		);
	}

	@computed
	get forgotPasswordButtonText(): string {
		return (
			(this.configuration && this.configuration.forgotPasswordButtonText) ||
			'Forgot Password?'
		);
	}

	@computed
	get registrationText(): string | undefined {
		return (
			this.configuration &&
			this.configuration.registrationLink &&
			this.configuration.registrationLink.text
		);
	}

	@computed
	get registrationUrl(): string | undefined {
		const registrationRedirect =
			this.configuration &&
			this.configuration.registrationLink &&
			this.configuration.registrationLink.redirectUrl;
		if (registrationRedirect) {
			const redirectUrl = this.getQueryValue(registrationRedirect);
			return redirectUrl;
		}
	}

	@computed
	get errorText(): string {
		return (
			(this.configuration && this.configuration.errorText) ||
			'The details you provided are invalid, please try again'
		);
	}

	public clear = () => {
		const { assuranceStore } = this.storeContext;
		this.password = '';
		this.username = '';
		assuranceStore.clear();
	};

	public updateUsername = (value: string) => {
		const { messageStore } = this.storeContext;
		this.username = value;
		messageStore.clearAll();
	};

	@action
	public validateUsername = () => {
		if (!this.hasUsername) {
			const { messageStore } = this.storeContext;
			messageStore.setErrorText('Username is required');
			return false;
		}
		return true;
	};

	public updatePassword = (value: string) => {
		const { messageStore } = this.storeContext;
		this.password = value;
		messageStore.clearError();
	};

	@action
	public validatePassword = () => {
		if (isNullOrEmpty(this.password)) {
			const { messageStore } = this.storeContext;
			messageStore.setErrorText('Password is required');
			return false;
		}
		return true;
	};

	@action
	public setRegisterStep = () => {
		const appStore = this.storeContext.appStore;
		appStore.setStep(config.loginSteps.REGISTER_USER);

		this.storeContext.registrationStore.initialize();
	};

	@action
	public registerClick = () => {
		const appStore = this.storeContext.appStore;

		if (this.registerRedirectOptions && this.registerRedirectURL) {
			appStore.redirectTo(this.registerRedirectURL);
		} else {
			this.setRegisterStep();
		}
	};

	@computed
	get registerRedirectURL() {
		const originBase = window.location.origin;
		const originPath = originBase.replace(window.location.href, '');
		const redirect = new helpers.RedirectHelper(originPath, originBase);

		if (this.registerRedirectOptions) {
			return redirect.getRedirectPath(this.registerRedirectOptions);
		}
	}

	@computed
	get registerRedirectOptions() {
		return (
			this.registrationConfiguration &&
			this.registrationConfiguration.redirectProps
		);
	}

	@computed
	get hasUsername() {
		return !isNullOrEmpty(this.username);
	}

	public checkUsernameForAssurance = async () => {
		if (this.hasUsername) {
			const { assuranceStore, kurtosysApiStore } = this.storeContext;
			const overrideOptions = {
				body: {
					username: this.username,
				},
			};
			const response = await kurtosysApiStore.assurance.execute(
				overrideOptions
			);
			assuranceStore.setAssurance(response);
		}
	};

	public validateUserLogin = async () => {
		if (this.validateUsername()) {
			if (this.validatePassword()) {
				const {
					appStore,
					messageStore,
					kurtosysApiStore,
					userLogin2FaStore,
					setupAssurancesStore,
				} = this.storeContext;
				const loadingKey = 'UserLoginStore.validateUserLogin';
				appStore.startLoading(loadingKey);
				const loginRequestBody = {
					username: this.username,
					password: this.password,
				};

				try {
					const overrideOptions = {
						body: loginRequestBody,
					};
					const response = await kurtosysApiStore.login.callApi(
						overrideOptions
					);
					if (response.status === 200) {
						this.token = await response.text();
						if (RegExp(/^2fa-/).test(this.token)) {
							const is2faSetup = await userLogin2FaStore.load2FASettings();
							if (is2faSetup) {
								appStore.setStep(config.loginSteps.USER_LOGIN_2FA);
							}
						} else {
							let setAssurances: boolean = false;
							if (appStore.isActionEnabled('assurance-setup')) {
								const user = await appStore.getUser();
								if (
									(user.assuranceImageEnabled && !user.assuranceImageSet) ||
									(user.assuranceMessageEnabled && !user.assuranceMessageSet)
								) {
									appStore.setStep(config.loginSteps.SETUP_ASSURANCES);
									setAssurances = true;
									const { setupAssurancesStore } = this.storeContext;
									setupAssurancesStore.source = SetupAssuranceSource.login;
								}
							}
							if (!setAssurances) {
								await appStore.refreshRedirectUrl();
								appStore.redirectTo(appStore.redirectURL);
							}
						}
					} else if (response.status === 403) {
						appStore.clear();
						appStore.setStep(config.loginSteps.ACCOUNT_LOCKED);
					} else {
						messageStore.setErrorText(this.errorText);
					}
					appStore.stopLoading(loadingKey);
				} catch (error) {
					appStore.stopLoading(loadingKey);
					messageStore.setErrorText(this.errorText);
				}
			}
		}
		return false;
	};

	@action
	public onForgotPassword = () => {
		const { appStore, messageStore } = this.storeContext;
		appStore.setStep(config.loginSteps.RESET_PASSWORD);
		messageStore.clearError();
	};

	@action
	public onForgotUsername = () => {
		const { appStore, messageStore } = this.storeContext;
		appStore.setStep(config.loginSteps.FORGOT_USERNAME);
		messageStore.clearError();
	};
}
