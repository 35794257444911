import { models } from '@kurtosys/ksys-app-template';
import { IAppConfigurationBase } from '../models/app/IAppConfigurationBase';

export const APPLICATION_CLIENT_CONFIGURATIONS: models.api.applicationManager.IApplicationClientConfiguration[] =
	[
		{
			applicationClientConfigurationId: 1,
			configuration: {
				culture: {
					base: 'Default',
					default: 'en-GB',
				},
				accessibilityConfig: {
					asset: {
						base: {
							accordionPanel: {
								collapseIcon: {
									value: 'Button to close the panel',
								},
								expandIcon: {
									value: 'Button to open the panel',
								},
							},
						},
					},
					tables: {
						performance: {
							caption: {
								value: 'Past Performance',
							},
						},
					},
				},
			} as IAppConfigurationBase,
			name: 'root',
			applicationClientConfigurationTypeId: 1,
		},
	];
